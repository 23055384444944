import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useProfunctorState from '@staltz/use-profunctor-state'
import { lastDayOfMonth, setDate, startOfYear } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { Title } from 'react-admin'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'
import type { FullRaf, ExtendedContact } from 'src/types/api/extendedTypes'

import { DEFALUT_STALE_TIME, useUseQuery } from '../../libs/useGetList'

import { ContactModal } from './ContactModals/Modal'
import { ExportToBeDone } from './Export'
import { ToBeDoneFilters } from './ToBeDoneFilter'
import type { ToBeDoneFilter } from './ToBeDoneFilter'
import { ToBeDoneList } from './ToBeDoneList'

export type AddressSelectedState = {
  selected: boolean
  contact?: ExtendedContact
}
export type AddressesSelectedState = Record<
  string,
  AddressSelectedState | undefined
>

const topBarStyle = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    rightBar: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

export function ToBeDone() {
  const selectedStore = useProfunctorState<AddressesSelectedState>({})
  const nxHttpClient = useNxHttpClient()

  const [filters, setFilters] = useState<ToBeDoneFilter | null>({
    interventionDate: { start: startOfYear(new Date()), end: new Date() },
    startPrice: 0,
  })

  const { data: rafList, isLoading } = useUseQuery({
    queryKey: ['getRaf', filters],
    queryFn: async () => {
      const encodedFilters = encodeFilters(filters!)
      const request = await nxHttpClient.patch<FullRaf[]>(
        `Adress/fullRaf`,
        encodedFilters,
      )
      return request.data.map((raf) => {
        return {
          ...raf,
          contacts:
            raf.contacts !== ''
              ? (JSON.parse(raf.contacts ?? '[]') as ExtendedContact[])
              : [],
          prix: raf.prix ? parseFloat(raf.prix) : undefined,
        }
      })
    },
    enabled: Boolean(filters !== null && filters.interventionDate),
    staleTime: DEFALUT_STALE_TIME,
  })

  function onSubmit(values: ToBeDoneFilter) {
    // const encodedFilters = encodeFilters(values)
    setFilters(values)
    selectedStore.setState(() => {
      return {}
    })
  }

  const selectedCount = useMemo(() => {
    return Object.values(selectedStore.state).filter(
      (select) => select?.selected,
    ).length
  }, [selectedStore.state])

  const { root, rightBar } = topBarStyle()

  return (
    <>
      <div className={root}>
        <Title title="Reste à faire" />
        <Typography variant="h3">Reste à faire</Typography>
        <div className={rightBar}>
          {selectedCount > 0 && (
            <Typography>{`${selectedCount} ${
              selectedCount < 2
                ? 'contact sélectionné'
                : 'contacts sélectionnés'
            }`}</Typography>
          )}
          <ExportToBeDone
            loading={isLoading}
            count={selectedCount}
            rafList={rafList ?? []}
            addressSelectedState={selectedStore.state}
          />

          <ContactModal
            contactType="SMS"
            count={selectedCount}
            addressesSelectedState={selectedStore.state}
          />
          <ContactModal
            contactType="MAIL"
            count={selectedCount}
            addressesSelectedState={selectedStore.state}
          />
        </div>
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <ToBeDoneFilters onSubmit={onSubmit} setFilters={setFilters} />
            </Grid>
            <Grid item xs={10}>
              <ToBeDoneList
                isLoading={isLoading}
                data={rafList ?? []}
                addressesSelectedStateStore={selectedStore}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

function encodeFilters(values: ToBeDoneFilter): any {
  const { interventionDate, dueDate, endPrice, startPrice } = values

  const start = interventionDate
    ? { start: interventionDate.start, end: interventionDate.end }
    : undefined

  const end = dueDate
    ? {
        start: setDate(dueDate, 1),
        end: lastDayOfMonth(dueDate),
      }
    : undefined
  return {
    ...values,
    interventionDate: start,
    dueDate: end,
    endPrice: endPrice !== 0 ? endPrice : undefined,
    startPrice: startPrice !== 0 ? startPrice : undefined,
  }
}
