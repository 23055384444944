import { useCallback, useMemo } from 'react'
import { required } from 'react-admin'
import { useFormState } from 'react-final-form'
import { Boundary } from 'src/components/Boundary'
import type { InterventionCreate } from 'src/types/api/extendedTypes'

import ContactReferenceInput from './ContactReferenceInput'
import { SuggestedResident } from './SuggestedResident'

type ResidentContactProps = {
  search: any
  setSearch: any
}
export const ResidentContact = (props: ResidentContactProps) => {
  const { search } = props
  const { values } = useFormState<InterventionCreate>({
    subscription: { values: true },
  })

  const { contrat_id } = values

  const filterToQuery = useCallback((searchText: string) => {
    return {
      nom: searchText,
    }
  }, [])
  const validate = useMemo(() => required(), [])

  if (!values.adresse_id) return null

  return (
    <div>
      <Boundary>
        <ContactReferenceInput
          inputLabel="Contact"
          target={'contact_id'}
          source="contact_id"
          reference="Contact"
          validate={validate}
          perPage={10}
          filterToQuery={filterToQuery}
          emptyContactInput={['contact_id']}
          isDisabled={Boolean(contrat_id)}
        />
        {!values.contact_id && !search && <SuggestedResident />}
      </Boundary>
    </div>
  )
}
