import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import type { Adress, Contact, Contrat } from '@willig/types/api'
import { differenceInDays } from 'date-fns'
import React from 'react'
import type { ListProps } from 'react-admin'
import {
  Datagrid,
  List,
  FunctionField,
  Link,
  BulkExportButton,
} from 'react-admin'
import { concatAddress, concatNames } from 'src/libs/concatNames'
import { useOne } from 'src/libs/useOne'

import { DefaultPagination } from '../../components/defaultPagination'

export const useStylesContrat = makeStyles((theme) => {
  return {
    greenContract: {
      color: 'inherit',
    },
    yellowContract: {
      fontWeight: 'bold',
      color: theme.palette.warning.main,
    },
    redContract: {
      fontWeight: 'bold',
      color: theme.palette.error.light,
    },
  }
})

export const ListView = (props: ListProps) => {
  const classes = useStylesContrat()

  function getDateStyle(diff: number) {
    if (diff < 0) return classes.redContract
    if (diff < 30) return classes.yellowContract
    return classes.greenContract
  }

  return (
    <List
      {...props}
      bulkActionButtons={<BulkExportButton />}
      pagination={<DefaultPagination />}
      perPage={50}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="edit">
        <FunctionField<Contrat>
          reference="id"
          source="name"
          label="Nom du contrat"
          render={(record) => record?.name}
        />
        <FunctionField<Contrat>
          reference="end_date"
          label="Date de fin"
          render={(record) => {
            const { end_date } = record ?? {}

            const dayDiff = differenceInDays(
              new Date(end_date!).getTime(),
              new Date().getTime(),
            )

            const dateClass = getDateStyle(dayDiff)

            return (
              <Typography className={dateClass}>
                {new Date(end_date!).toLocaleDateString()}
              </Typography>
            )
          }}
        />
        <FunctionField<Contrat>
          reference="type"
          source="type"
          label="Type"
          render={(record) => record?.type}
        />

        <FunctionField<Contrat>
          reference="invoice_contact_id"
          label="Contact de facturation"
          render={(record) => <ContactName pro={record} />}
        />

        <FunctionField<Contrat>
          reference="invoice_address_id"
          label="Adresse de facturation"
          render={(record) => <AdressName pro={record} />}
        />
      </Datagrid>
    </List>
  )
}

type AddressNameProps = {
  pro: Contrat | undefined
}

function AdressName(props: AddressNameProps) {
  const { pro } = props
  const { data: adressName } = useOne<Adress>(
    'Adress',
    {
      id: pro?.invoice_address_id ?? 'null',
    },
    { enabled: Boolean(pro?.invoice_address_id) },
  )
  return (
    <Link to={`/adress/${adressName?.data.id}`} target="_blank">
      {concatAddress({
        rue: adressName?.data.rue,
        code_postal: adressName?.data.code_postal,
        ville: adressName?.data.ville,
        cote: adressName?.data.cote,
      } as Adress)}
    </Link>
  )
}

function ContactName(props: AddressNameProps) {
  const { pro } = props
  const { data: contact } = useOne<Contact>(
    'Contact',
    {
      id: pro?.invoice_contact_id ?? 'null',
    },
    { enabled: Boolean(pro?.invoice_contact_id) },
  )
  return (
    <Link to={`/contact/${contact?.data.id}`} target="_blank">
      {concatNames(contact?.data)}
    </Link>
  )
}
