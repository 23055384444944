import type { DailyInvoices } from 'src/types/api/extendedTypes'

import { SinaoStatusEnum } from '../../../types/api/extendedTypes'
import type {
  LocalFilter,
  ParticularFilter,
  ProFilters,
  PaymentType,
} from '../types'

export function filterDataLocally(
  isPro: boolean,
  data: DailyInvoices[],
  filters: Partial<LocalFilter>,
) {
  if (isPro) return filterProData(data, filters)
  return filterParticularData(data, filters)
}

function filterProData(data: DailyInvoices[], filters: Partial<ProFilters>) {
  const { contrat_id } = filters
  if (!contrat_id) return data
  return data.filter((invoice) => invoice?.contrat_id === contrat_id)
}

function filterParticularData(
  data: DailyInvoices[],
  filters: Partial<ParticularFilter>,
) {
  const { ramoneur, type_paiement, sinaoStatus } = filters

  const paiementType = getPaimentType(type_paiement)

  let filteredDatas = data

  if (ramoneur) {
    filteredDatas = filteredDatas.filter((inv) => inv.ramoneur === ramoneur)
  }

  if (paiementType !== undefined) {
    if (paiementType === 'payé') {
      filteredDatas = filteredDatas.filter((inv) => inv.type_paiement !== null)
    } else {
      filteredDatas = filteredDatas.filter(
        (inv) => inv.type_paiement === paiementType,
      )
    }
  }
  if (sinaoStatus) {
    filteredDatas = filteredDatas.filter((inv) => {
      if (sinaoStatus === SinaoStatusEnum.notBilled) {
        return inv.sinao_invoice_status === null
      }
      if (sinaoStatus === SinaoStatusEnum.billed) {
        return inv.sinao_invoice_status !== null
      }
      return inv.sinao_invoice_status === sinaoStatus
    })
  }

  return filteredDatas
}

function getPaimentType(
  paimentType?: PaymentType,
): PaymentType | null | undefined {
  if (!paimentType) return undefined
  if (paimentType === 'impayé') return null
  return paimentType
}
