import { GetApp as GetAppIcon } from '@material-ui/icons'
import type { Combustible, Contact } from '@willig/types/api'
import xlsx from 'json-as-xlsx'
import { useMemo } from 'react'
import type { Identifier } from 'react-admin'
import { Button } from 'react-admin'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'
import { useList, useUseQuery } from 'src/libs/useGetList'

type ExportDataProps = {
  id: Identifier | undefined
  label?: string
  target: string
}

type ExportedData = {
  contact_genre: string
  contact_prenom: string
  contact_nom: string
  contact_telephone?: string
  adresse_rue: string
  adresse_code_postal: string
  adresse_ville: string
  adresse_etage: string
  adresse_cote: string
  adresse_id: string
  installation_id: string
  installation_code_pro: string
  installation_identifiant: string
  installation_commentaire: string
  installation_nb_conduits: string
  installation_commentaire_conduits: string
  installation_prix_conduits: number | string
  installation_nb_tuyaux: number
  installation_commentaire_tuyaux: string
  installation_prix_tuyaux: number | string
  installation_nb_appareils: number
  installation_commentaire_appareils: string
  installation_prix_appareils: number
  adresse_commentaire: string
  installation_libelle?: string
  installation_combustible: {
    code: string
    label: string
  }
  installation_prix_total: string
  installation_last_intervention: string
  contrat_prerequis: string
  contrat_numero_devis: number
  contrat_numero_commande: string
}

export function ExportData(props: ExportDataProps) {
  const { id, label = 'Telecharger le fichier', target } = props
  const { data: combustibles } = useList<Combustible>('Combustible', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'code', order: 'ASC' },
    filter: {},
  })

  const nxHttpClient = useNxHttpClient()
  const endpoint = target === 'pro' ? 'Pro/exportData' : 'Contact/exportData'
  const payload = target === 'pro' ? 'pro_id' : 'contact_id'
  const { data: exportData, isLoading } = useUseQuery<ExportedData[]>({
    queryKey: ['contrat', 'export', 'getExportData'],
    queryFn: async () => {
      const request = await nxHttpClient.patch<ExportedData[]>(endpoint, {
        [payload]: id,
      })
      return request.data
    },
    enabled: Boolean(id),
  })

  const { data: contacts } = useList<Contact>('Contact', {
    filter: { pro_id: id },
    pagination: { page: 0, perPage: 1000 },
    sort: { order: 'DESC', field: 'id' },
  })

  const data = useMemo(() => {
    return [
      {
        sheet: `Marché`,
        columns: [
          {
            label: 'Pré-requis',
            value: (value: ExportedData) => {
              return value.contrat_prerequis
            },
          },
          {
            label: 'Genre contact',
            value: (value: ExportedData) => {
              return target === 'pro' ? value.contact_genre : ''
            },
          },
          {
            label: 'Prénom contact',
            value: (value: ExportedData) => {
              return target === 'pro' ? value.contact_prenom : ''
            },
          },
          {
            label: 'Nom contact',
            value: (value: ExportedData) => {
              return target === 'pro' ? value.contact_nom : ''
            },
          },
          {
            label: 'Téléphone contact',
            value: (value: ExportedData) => {
              return target === 'pro'
                ? value?.contact_telephone?.replaceAll(/[^0-9]/gm, '') ?? ''
                : ''
            },
          },
          {
            label: 'Rue',
            value: (value: ExportedData) => {
              return value.adresse_rue
            },
          },
          {
            label: 'Code Postal',
            value: (value: ExportedData) => {
              return value.adresse_code_postal
            },
          },
          {
            label: 'Ville',
            value: (value: ExportedData) => {
              return value.adresse_ville
            },
          },
          {
            label: 'Etage',
            value: (value: ExportedData) => {
              return value.adresse_etage
            },
          },
          {
            label: "Intitulé d'adresse",
            value: (value: ExportedData) => {
              return value.adresse_cote
            },
          },
          {
            label: 'Commentaire adresse',
            value: (value: ExportedData) => {
              return value.adresse_commentaire ?? ''
            },
          },
          {
            label: 'Numéro devis',
            value: (value: ExportedData) => {
              return value.contrat_numero_devis
            },
          },
          {
            label: 'Numéro de commande',
            value: (value: ExportedData) => {
              return value.contrat_numero_commande
            },
          },
          {
            label: "Identifiant client de l'adresse",
            value: (value: ExportedData) => {
              const foundContact = contacts?.find(
                (contact) =>
                  contact.nom === value.contact_nom &&
                  contact.prenom === value.contact_prenom,
              )
              return foundContact?.id ?? null
            },
          },
          {
            label: "Identifiant de l'installation",
            value: (value: ExportedData) => {
              return value.installation_identifiant ?? ''
            },
          },
          {
            label: 'commentaire installation',
            value: (value: ExportedData) => {
              return value.installation_commentaire
            },
          },
          {
            label: 'Nombre de conduits',
            value: (value: ExportedData) => {
              return value.installation_nb_conduits
            },
          },
          {
            label: 'Information conduits',
            value: (value: ExportedData) => {
              return value.installation_commentaire_conduits
            },
          },
          {
            label: 'Prix conduits OU Pack HT',
            value: (value: ExportedData) => {
              return Number(value.installation_prix_conduits)
            },
          },
          {
            label: 'Nombre de tuyau',
            value: (value: ExportedData) => {
              return value.installation_nb_tuyaux
            },
          },
          {
            label: 'Informations tuyaux',
            value: (value: ExportedData) => {
              return value.installation_commentaire_tuyaux
            },
          },
          {
            label: 'Prix tuyaux',
            value: (value: ExportedData) => {
              return Number(value.installation_prix_tuyaux)
            },
          },
          {
            label: "Nombre d'appareils",
            value: (value: ExportedData) => {
              return value.installation_nb_appareils
            },
          },
          {
            label: 'Informations appareils',
            value: (value: ExportedData) => {
              return value.installation_commentaire_appareils
            },
          },
          {
            label: 'Prix appareils',
            value: (value: ExportedData) => {
              return Number(value.installation_prix_appareils)
            },
          },
          {
            label: 'Combustible',
            value: (value: ExportedData) => {
              return value.installation_combustible.code
            },
          },
          {
            label: 'Prix total HT',
            value: (value: ExportedData) => {
              return Number(value.installation_prix_total)
            },
            format: '0.00',
          },
          {
            label: 'Dernière intervention',
            value: (value: ExportedData) => {
              return value.installation_last_intervention
            },
          },
          {
            label: 'Id Adresse Interne',
            value: (value: ExportedData) => {
              return value.adresse_id
            },
          },
          {
            label: 'Id installation interne',
            value: (value: ExportedData) => {
              return value.installation_id ?? ''
            },
          },
        ],
        content: exportData,
        contacts,
      },
      {
        sheet: `Combustibles`,
        columns: [
          {
            label: 'Code à utiliser',
            value: (value: Combustible) => {
              return value.code
            },
          },
          {
            label: 'Correspond à',
            value: (value: Combustible) => {
              return value.label
            },
          },
        ],
        content: combustibles,
      },
    ]
  }, [combustibles, exportData, contacts, target])

  function exportDatas() {
    const settings = {
      fileName: `Export ${target}`, // Name of the spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }
    xlsx(data, settings)
  }
  return (
    <Button
      style={{ fontSize: '16px' }}
      disabled={isLoading}
      color="secondary"
      variant="contained"
      label={label}
      onClick={exportDatas}
    >
      <GetAppIcon />
    </Button>
  )
}
