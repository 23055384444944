import type { Theme } from '@material-ui/core'
import { Box, CircularProgress, Typography } from '@material-ui/core'

import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import type { Contrat, FullContratAddress } from '@willig/types/api'

import { differenceInDays } from 'date-fns'
import { Suspense } from 'react'
import {
  Button,
  List,
  useDelete,
  Datagrid,
  TextField,
  FunctionField,
  useDataProvider,
} from 'react-admin'

import { Boundary } from 'src/components/Boundary'
import { DefaultPagination } from 'src/components/defaultPagination'
import { useInvalidate } from 'src/hooks/useInvalidate'

import { useUseQuery } from 'src/libs/useGetList'

import { useStylesContrat } from '../contrat/List'

import { AddContractModal } from './AddContractModal'

const useStyles = makeStyles((theme: Theme) => {
  return {
    deleteButton: { color: theme.palette.error.main },
  }
})

type Props = {
  addressId: string
}

export function ContractTable(props: Props) {
  const { addressId } = props
  const [deleteLink] = useDelete()

  const { deleteButton } = useStyles()
  const invalidate = useInvalidate()

  const classes = useStylesContrat()

  function getDateStyle(diff: number) {
    if (diff < 0) return classes.redContract
    if (diff < 30) return classes.yellowContract
    return classes.greenContract
  }

  function deleteContractRelation(record: FullContratAddress) {
    if (
      window.confirm(
        `Le contrat ${record.contrat_name} ne sera plus lié à cette adresse, continuer ? `,
      )
    ) {
      deleteLink('ContratAddress', record.id, record, {
        onSuccess() {
          invalidate('FullContratAddress')
        },
      })
    }
  }

  if (!addressId) return <></>

  return (
    <>
      <Typography variant={'overline'}>{'Liste des contrats'}</Typography>
      <List
        key={addressId}
        filter={{ address_id: [addressId], deleted: [false] }}
        resource="FullContratAddress"
        bulkActionButtons={false}
        pagination={<DefaultPagination />}
        actions={
          <Boundary>
            <AddContractModal />
          </Boundary>
        }
        perPage={25}
        basePath="/FullContratAddress"
        empty={false}
      >
        <Datagrid
          basePath="/"
          rowClick={(_id, _base, record) => {
            return `/Contrat/${(record as FullContratAddress).contrat_id}`
          }}
        >
          <TextField source="contrat_name" label="Nom" />

          <FunctionField<FullContratAddress>
            label="Type"
            render={(record) => {
              if (!record) return <></>

              return (
                <Suspense fallback={<CircularProgress />}>
                  <ContratType contratId={record!.contrat_id!} />
                </Suspense>
              )
            }}
          />
          <TextField source="tva" label="Tva" />
          <FunctionField<FullContratAddress>
            reference="contrat_end_date"
            label="Date de fin"
            render={(record) => {
              const { contrat_end_date } = record ?? {}

              const dayDiff = differenceInDays(
                new Date(contrat_end_date!).getTime(),
                new Date().getTime(),
              )

              const dateClass = getDateStyle(dayDiff)

              return (
                <Typography className={dateClass}>
                  {new Date(contrat_end_date!).toLocaleDateString()}
                </Typography>
              )
            }}
          />
          <FunctionField<FullContratAddress>
            render={(record) => {
              if (!record) return <></>

              return (
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation()
                      deleteContractRelation(record)
                    }}
                    className={deleteButton}
                  >
                    <>
                      <DeleteForeverIcon /> Délier
                    </>
                  </Button>
                </Box>
              )
            }}
          />
        </Datagrid>
      </List>
    </>
  )
}

type ExtendedContrat = Contrat & { tvaLabel: string }

function ContratType(props: { contratId: string }) {
  const { contratId } = props
  const dataProvider = useDataProvider()

  const { data: contrat } = useUseQuery<ExtendedContrat>({
    queryKey: ['contrat', contratId, 'getOne'],
    queryFn: async () => {
      const value = await dataProvider.getOne<ExtendedContrat>('Contrat', {
        id: contratId,
      })
      return value.data
    },
    suspense: true,
  })

  return <Typography>{contrat?.type}</Typography>
}
