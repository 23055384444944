import type { Theme } from '@material-ui/core'
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import type { FullStat } from '@willig/types/api'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'
import { Boundary } from 'src/components/Boundary'
import { useUseQuery } from 'src/libs/useGetList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    tableView: {
      backgroundColor: theme.palette.primary.light,
      '& th': {
        fontWeight: 600,
        color: 'white',
        fontSize: '16px',
      },
    },
  }),
)

export function FullStats() {
  return (
    <>
      <Typography variant="h3" style={{ margin: '20px 0' }}>
        Contrat/Syndic à venir
      </Typography>
      <Boundary>
        <FullStatsImpl type="pro" />
      </Boundary>

      <Typography variant="h3" style={{ margin: '20px 0' }}>
        Zones prioritaires
      </Typography>
      <Boundary>
        <FullStatsImpl type="particulier" />
      </Boundary>
    </>
  )
}

function FullStatsImpl(props: { type: 'pro' | 'particulier' }) {
  const { type } = props
  const nxHttpClient = useNxHttpClient()
  const { tableView } = useStyles()
  const { data } = useUseQuery({
    queryKey: ['getList', type],
    queryFn: async () => {
      const tt = await nxHttpClient.get<FullStat[]>('/FullStat')
      return tt?.data?.filter((stat) => stat.type === type)
    },
    suspense: true,
  })
  if (!data || data.length === 0) return <NoData />
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow className={tableView}>
            {data.map((stat, index) => (
              <TableCell key={index} component="th" scope="row">
                {`${stat.mo}`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {data.map((stat, index) => (
              <TableCell
                key={index}
                component="th"
                scope="row"
                style={{ verticalAlign: 'text-top' }}
              >
                {parsed(stat, false)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function parsed(stat: FullStat, isPro: boolean) {
  const splittedStat = stat.todo?.split(', ') ?? []
  const regex = /(.*) \((\d+)\)/
  return (
    <>
      {splittedStat.map((list: string) => {
        const [, city, count] = list.match(regex) ?? []
        const color = parseInt(count) > 50 ? '' : 'gray'
        const fontWeight = parseInt(count) > 50 ? 'bold' : ''

        if (isPro && parseInt(count) < 9) return <></>

        return (
          <p style={{ display: 'flex', gap: '8px', color, fontWeight }}>
            <span>{count}</span>
            {city}
          </p>
        )
      })}
    </>
  )
}

function NoData() {
  return (
    <Typography variant="h6" style={{ margin: '20px 0' }}>
      Pas de données pour le moment ...
    </Typography>
  )
}
