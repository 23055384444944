import { Switch, Grid, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import type {
  FullContactAdresse,
  Intervention,
  Adress,
  Contrat,
  FullContratAddress,
} from '@willig/types/api'
import queryString from 'query-string'
import { equals, isEmpty } from 'rambda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SaveButton, useNotify, useGetOne } from 'react-admin'

import { useForm, useFormState } from 'react-final-form'
import { useLocation } from 'react-router-dom'
import { Boundary } from 'src/components/Boundary'
import { SelectInput } from 'src/components/SelectInput'

import { useList } from 'src/libs/useGetList'
import type { InterventionCreate } from 'src/types/api/extendedTypes'
import { theme } from 'src/UI/theme'

import { MeetingPropositionButton } from './FreeSlots/MeetingProposalButton'
import { InvoiceContactInfo } from './interventionFormComponents/InvoiceContactInfo'
import { Relationships } from './interventionFormComponents/Relationships'
import { ResidentInformation } from './interventionFormComponents/ResidentInformation'
import { StaticFields } from './interventionFormComponents/StaticFields'

const useStyles = makeStyles({
  cardWrapper: {
    display: 'grid',
    gridTemplateColumns: ' 1fr 1fr',
    width: '100%',
    columnGap: '2rem',
  },
  suggestedCard: {
    minWidth: 275,
    display: 'flex',
    alignItems: 'flex-end',
  },
  suggestedInfo: {
    flexGrow: 1,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  relationForm: {
    gap: '1rem',
    borderColor: theme.palette.primary.main,
  },
  interventionForm: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  interventionFormButton: {
    margin: '1rem 1.5rem 1rem 0',
  },
  greenContract: {
    backgroundColor: theme.palette.primary.light,
    margin: '1rem 1.5rem 1rem 0',
  },
  yellowContract: {
    backgroundColor: theme.palette.warning.light,
    margin: '1rem 1.5rem 1rem 0',
  },
  redContract: {
    backgroundColor: theme.palette.error.light,
    margin: '1rem 1.5rem 1rem 0',
  },
})

type interventionFieldsProps = {
  isEdit?: boolean
  handleSubmitWithRedirect: any
  saving: any
  record?: Intervention
}

export const InterventionFields = (props: interventionFieldsProps) => {
  const { isEdit = false, saving, handleSubmitWithRedirect, record } = props
  const notify = useNotify()
  const form = useForm() // form used to change values
  const classes = useStyles()

  const location = useLocation()
  const searchQuery = location?.search

  const { values } = useFormState<Partial<InterventionCreate>>({
    subscription: { values: true },
  }) // current values of every field in the form

  const { isOtherBillingContact, contrat_id, contact_id } = values

  const isContract = Boolean(contrat_id)

  const errorMessage = getErrorMessages(values, isContract)

  const [search, setSearch] = useState(isEdit) // state of the contact part if search show input if not show suggested

  const [searchFacture, setSearchFacture] = useState(isEdit)

  const toggleBillingContact = useCallback(() => {
    if (isOtherBillingContact) {
      form.change('facture_contact_id', null)
      form.change('facture_adresse_id', null)
      form.change('isOtherBillingContact', false)
    } else {
      form.change('isOtherBillingContact', true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])
  const { data: adressePayor } = useList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        '!deleted': [1],
        adresse_id: [values.adresse_id],
        is_payor: [1],
      },
    },
    { enabled: Boolean(values.adresse_id) },
  )

  useEffect(() => {
    if (isOtherBillingContact && !isEmpty(adressePayor) && !isContract) {
      form.change('facture_contact_id', adressePayor[0].contact_id)
    }
    if (adressePayor.length > 1) {
      notify(
        'Erreur  : plusieurs payeurs renseignés, veuillez vérifier dans la base',
        'error',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adressePayor,
    isOtherBillingContact,
    notify,
    toggleBillingContact,
    contact_id,
    isContract,
  ])

  useEffect(() => {
    if (!isEmpty(adressePayor)) {
      form.change('facture_contact_id', adressePayor[0].contact_id)
      form.change('isOtherBillingContact', true)
    }

    if (isEmpty(adressePayor) && !isEdit) {
      form.change('facture_contact_id', null)
      form.change('isOtherBillingContact', false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adressePayor, form])

  useEffect(() => {
    if (searchQuery) {
      const parsedQueries = queryString.parse(searchQuery)
      if (parsedQueries.contact_id === undefined) {
        form.change('adresse_id', parsedQueries.adress_id)
        setSearch(false)
      } else {
        setSearch(true)
        form.change('adresse_id', parsedQueries.adress_id)
        form.change('contact_id', parsedQueries.contact_id)
      }
    }
  }, [form, searchQuery])

  const { data: Address } = useGetOne<Adress>('Adress', values.adresse_id!, {
    enabled: Boolean(values.adresse_id),
  })

  const readOnlyContactPayor = Boolean(
    values.facture_contact_id && adressePayor.length > 0,
  )

  const { adresse_id } = values

  const canEditBillingContact = values.contrat_id === undefined

  return (
    <div className={classes.interventionForm}>
      <Card variant="outlined">
        <CardContent className={classes.cardWrapper}>
          <ResidentInformation
            search={search}
            setSearch={setSearch}
            searchQuery={searchQuery}
          />
        </CardContent>
      </Card>

      <ContratPicker addressId={adresse_id} />

      {!isEdit && Address && <MeetingPropositionButton address={Address} />}

      <Card variant="outlined">
        <CardContent>
          <Box>
            {readOnlyContactPayor ? (
              <Typography color="error">
                Le contact de facturation ne peut être changé que sur le détail
                de l'adresse d'intervention
              </Typography>
            ) : (
              <>
                <h3>Contact de facturation</h3>
                {canEditBillingContact && (
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Contact</Grid>
                    <Grid item>
                      <Switch
                        checked={isOtherBillingContact}
                        onChange={toggleBillingContact}
                      />
                    </Grid>
                    <Grid item>Autre contact</Grid>
                  </Grid>
                )}
              </>
            )}
          </Box>

          {isOtherBillingContact && (
            <div className={classes.cardWrapper}>
              <InvoiceContactInfo
                isDisabled={readOnlyContactPayor || !canEditBillingContact}
                setSearchFacture={setSearchFacture}
                searchFacture={searchFacture}
              />
            </div>
          )}
        </CardContent>
      </Card>
      <Boundary>
        <Relationships
          otherEntriesState={values.otherEntries ?? {}}
          setOtherEntriesState={useCallback(
            (updateFunc) => {
              const prevValue = values.otherEntries ?? {}
              const newValue = updateFunc(prevValue)
              if (!equals(prevValue, newValue)) {
                form.change('otherEntries', newValue)
              }
            },
            [form, values.otherEntries],
          )}
        />
      </Boundary>
      <StaticFields record={record} />
      <Box
        width="full"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <SaveButton
          label={errorMessage ?? "Enregistrer l'intervention"}
          className={classes.interventionFormButton}
          saving={saving}
          disabled={Boolean(errorMessage || saving)}
          handleSubmitWithRedirect={handleSubmitWithRedirect}
        />
      </Box>
    </div>
  )
}

type ContratPickerProps = {
  addressId?: string
}

function ContratPicker(props: ContratPickerProps) {
  const { addressId } = props

  const { data: relatedContracts } = useList<FullContratAddress>(
    'FullContratAddress',
    {
      filter: { address_id: [addressId], deleted: [false] },
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'id', order: 'ASC' },
    },
    { enabled: Boolean(addressId) },
  )

  const contractIds = useMemo(() => {
    return relatedContracts?.map(
      (relatedContract) => relatedContract.contrat_id,
    )
  }, [relatedContracts])

  if (!contractIds || !contractIds.length) return <></>

  return (
    <Card variant="outlined">
      <Boundary>
        <ContratPickerImpl contractIds={contractIds} />
      </Boundary>
    </Card>
  )
}

type ContratPickerImplProps = {
  contractIds: string[]
}

function ContratPickerImpl(props: ContratPickerImplProps) {
  const { contractIds } = props
  const { values } = useFormState<Intervention>({
    subscription: { values: true },
  })

  const { data } = useList<Contrat>(
    'Contrat',
    {
      pagination: { page: 0, perPage: 0 },
      sort: { field: 'id', order: 'DESC' },
      filter: { id: contractIds },
    },
    { suspense: true },
  )
  const form = useForm<InterventionCreate>()

  const options = data?.map((dt) => {
    return {
      value: dt.id,
      name: dt.name,
    }
  })
  if (!options) return null

  function onContractChange(id?: string) {
    form.change('contrat_id', id)
    if (!id) {
      form.change('isOtherBillingContact', false)
      form.change('contact_id', undefined)
    }
    const actualContrat = data?.find((contrat) => contrat.id === id)
    if (!actualContrat) return
    const { invoice_contact_id, invoice_address_id } = actualContrat
    form.change('isOtherBillingContact', true)
    form.change('contact_id', invoice_contact_id)
    form.change('facture_contact_id', invoice_contact_id)
    form.change('facture_adresse_id', invoice_address_id)
  }

  return (
    <CardContent>
      <SelectInput
        label={'Contrat'}
        choices={[{ value: undefined, name: 'Aucun contrat' }, ...options]}
        variant="filled"
        initialState={values?.contrat_id}
        onChange={(value) => {
          onContractChange(value)
        }}
      />
    </CardContent>
  )
}

function getErrorMessages(
  values: Partial<InterventionCreate>,
  isContract: boolean,
) {
  const { contact_relation_type, invoice_relation_type } = values
  if (contact_relation_type === invoice_relation_type) {
    return 'Relation entre contact et adresse du même type'
  }
  if (!isContract && !values.contact_id) return 'Contact manquant'

  if (isContract && !values.facture_adresse_id)
    return 'Adresse de facturation manquante'

  if (values.contact_id && !values.contact_relation_type)
    return 'Relation entre contact et adresse manquant'

  if (!values.adresse_id) return 'Adresse manquante'
  if (Object.values(values.otherEntries ?? {}).some((e) => e === 0))
    return 'Il manque une relation entre Adresse et Contact'
}
