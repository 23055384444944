import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import type { FullTown, Anomalie } from '@willig/types/api'
import { startOfYear } from 'date-fns'

import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useNotify } from 'react-admin'
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form'
import { DatePicker, FormInput, Picker } from 'src/components/Form'
import { useList } from 'src/libs/useGetList'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }),
)

type ToBeDoneFiltersProps = {
  onSubmit: (filter: ToBeDoneFilter) => void
  setFilters: any
}

export type ToBeDoneFilter = {
  zone?: string
  city?: string
  anomaly?: string
  startPrice: number
  endPrice?: number
  sms?: 1 | 0
  email?: 1 | 0
  withoutIntervention?: true
  months?: number
  year?: number
  interventionDate: {
    start?: Date
    end?: Date
  }
  dueDate?: Date | null
}

export function ToBeDoneFilters(props: ToBeDoneFiltersProps) {
  const { onSubmit, setFilters } = props
  const methods = useForm<ToBeDoneFilter>({
    defaultValues: {
      interventionDate: { start: startOfYear(new Date()), end: new Date() },
      dueDate: null,
      zone: '',
      city: '',
    },
  })
  const { control, handleSubmit, watch } = methods

  const { data: zones } = useList('Zone', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'label', order: 'asc' },
  })

  const { data: fullZones } = useList('FullZoneCity', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'label', order: 'asc' },
  })

  const { data: villes } = useList<FullTown>('FullTown', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'ville', order: 'asc' },
  })

  const { data: anomalies } = useList<Anomalie>('Anomalie', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'code_long', order: 'asc' },
  })

  const watchedStartPrice = watch('startPrice')
  const watchedZones = watch('zone')
  const watchedInterventionDate = watch('interventionDate')
  const notify = useNotify()

  const filteredCities = useMemo(() => {
    if (fullZones && watchedZones) {
      return fullZones.filter(
        (cities) => cities.zone.toString() === watchedZones?.toString(),
      )[0].cities
    }
    if (fullZones && villes && !watchedZones) {
      return villes
    }
  }, [watchedZones, fullZones, villes])

  const { form } = useStyles()

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <form
          className={form}
          onSubmit={handleSubmit(onSubmit, (error) => {
            Object.values(error).forEach((err) => {
              return notify(`${err}`, 'warning', {}, false, 1500)
            })
            setFilters(null)
          })}
        >
          <FormGroup label="Région">
            <Picker
              options={
                zones.map((zone) => {
                  return {
                    value: zone.id,
                    label: zone.label,
                  }
                }) ?? []
              }
              name="zone"
              label="Zone"
            />
            <Picker
              options={
                filteredCities?.map((town: any) => {
                  return { value: town.id ?? town, label: town.ville ?? town }
                }) ?? []
              }
              name="city"
              label="Ville"
            />
          </FormGroup>
          <FormGroup label="Dernière intervention">
            <DatePicker
              name="interventionDate.start"
              label="Effectuée entre le"
              maxDate={watchedInterventionDate?.end}
            />
            <DatePicker
              name="interventionDate.end"
              label="Et le"
              minDate={watchedInterventionDate?.start}
            />
          </FormGroup>
          <FormGroup label="Date d'échéance sur l'installation">
            <DatePicker
              label="Au mois de"
              name="dueDate"
              views={['month', 'year']}
            />
          </FormGroup>

          <FormGroup label="Anomalies sur l'installation">
            <Picker
              name="anomaly"
              label="Anomalie"
              options={
                anomalies.map((anomaly) => {
                  return { value: anomaly.id, label: anomaly.code_long }
                }) ?? []
              }
            />
          </FormGroup>
          <FormGroup label="Prix de l'intervention">
            <Controller
              name="startPrice"
              control={control}
              rules={{
                validate: (value) =>
                  value === undefined ||
                  !Number.isNaN(value) ||
                  'Veuillez entrer un nombre valide',
                min: {
                  value: 0,
                  message: 'Le prix de départ ne peut pas être négatif',
                },
              }}
              render={(controllerProps) => {
                const {
                  field,
                  fieldState: { error },
                } = controllerProps
                return (
                  <FormInput label="Prix entre">
                    <TextField
                      variant="filled"
                      type="number"
                      {...field}
                      error={!!error}
                    />
                  </FormInput>
                )
              }}
            />
          </FormGroup>
          <Controller
            name="endPrice"
            control={control}
            rules={{
              validate: (value) =>
                value !== undefined ||
                !Number.isNaN(value) ||
                'Veuillez entrer un nombre valide',
              min: {
                value: watchedStartPrice > 0 ? watchedStartPrice : 0,
                message:
                  'Le prix max ne doit pas être inférieur au prix de départ',
              },
            }}
            render={(controllerProps) => {
              const {
                field,
                fieldState: { error },
              } = controllerProps
              return (
                <FormInput label="et">
                  <TextField
                    variant="filled"
                    type="number"
                    {...field}
                    error={!!error}
                  />
                </FormInput>
              )
            }}
          />

          <FormGroup label="Accepte le contact">
            <Picker
              name="sms"
              label="Par SMS"
              options={[
                { label: 'oui', value: 1 },
                { label: 'non', value: 0 },
              ]}
            />
            <Picker
              name="email"
              label="Par email"
              options={[
                { label: 'oui', value: 1 },
                { label: 'non', value: 0 },
              ]}
            />
          </FormGroup>

          <FormGroup label="Rendez vous">
            <Controller
              name="withoutIntervention"
              control={control}
              render={(controllerProps) => {
                const { field } = controllerProps

                const { onChange, value } = field
                const handleChange = (
                  event: React.ChangeEvent,
                  checked: boolean,
                ) => {
                  if (!checked) {
                    return onChange(undefined)
                  }
                  onChange(event)
                }

                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        value={value ?? false}
                        onChange={handleChange}
                      />
                    }
                    label="Sans rendez-vous"
                  />
                )
              }}
            />
          </FormGroup>
          <FilterButton />
        </form>
      </MuiPickersUtilsProvider>
    </FormProvider>
  )
}

function FilterButton() {
  const interventionDate = useWatch({ name: 'interventionDate' })
  const isValid = Boolean(
    interventionDate &&
      interventionDate.start &&
      interventionDate.end &&
      interventionDate.start.getTime() <= interventionDate.end.getTime(),
  )

  return (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      disabled={!isValid}
    >
      Rechercher
    </Button>
  )
}

const useFormGroupStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(1),
      margin: `${theme.spacing(1)}px 0`,
    },
  }),
)

type FormGroupProps = {
  label?: string
}

function FormGroup(props: React.PropsWithChildren<FormGroupProps>) {
  const { children, label } = props
  const style = useFormGroupStyles()
  return (
    <div className={style.root}>
      {label && <Typography variant="subtitle2">{label}</Typography>}
      {children}
    </div>
  )
}
