import type { User } from '@willig/types/api'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useUseQuery } from 'src/libs/useGetList'

export function useSweeper(id: string, options?: UseQueryOptions) {
  const dataProvider = useDataProvider()
  const { data: sweeper } = useUseQuery({
    queryKey: ['User', id],
    queryFn: async () => {
      const { data } = await dataProvider.getOne('User', { id })
      return data as User
    },
    staleTime: Infinity,
    ...options,
  })

  return sweeper as User
}
