import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import type { Contact } from '@willig/types/api'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import xlsx from 'json-as-xlsx'
import _ from 'lodash'
import type { ExtendedFullRaf } from 'src/types/api/extendedTypes'
import { button } from 'src/UI/theme/mixins'

import type { AddressesSelectedState } from '.'

type ExportToBeDoneProps = {
  loading: boolean
  count: number
  rafList: ExtendedFullRaf[]
  addressSelectedState: AddressesSelectedState
}

type SelectedRaf = ExtendedFullRaf & {
  contact: Contact
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      margin: theme.spacing(0, 1),
      position: 'relative',
    },
    buttonProgress: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    exportButton: {
      ...button(),
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.light,
      },
    },
  }),
)

export function ExportToBeDone(props: ExportToBeDoneProps) {
  const { loading, count, rafList, addressSelectedState } = props
  const { buttonWrapper, buttonProgress, exportButton } = useStyles()

  function filteredRaf() {
    const selected = rafList
      ?.filter((raf) => {
        return addressSelectedState[raf.id]?.selected
      })
      .map((raf) => {
        return { ...raf, contact: addressSelectedState[raf.id]?.contact }
      })
    return _.sortBy(selected, 'next_intervention_date')
  }

  function exportToBeDone() {
    const filteredRafList = filteredRaf()
    const data = [
      {
        sheet: 'Reste à faire sélectionné',
        columns: [
          {
            label: "Date d'échéance",
            value: (selectedRaf: SelectedRaf) => {
              const { next_intervention_date } = selectedRaf
              if (!next_intervention_date) {
                return
              }
              return format(new Date(next_intervention_date), 'd MMMM yyyy', {
                locale: fr,
              })
            },
          },
          {
            label: 'Rendez-vous pris',
            value: (selectedRaf: SelectedRaf) => {
              const { hasintervention } = selectedRaf
              if (hasintervention === '0') return 'non'
              return 'oui'
            },
          },
          {
            label: 'Contact',

            value: (selectedRaf: SelectedRaf) => {
              const { contact } = selectedRaf
              if (!contact) return ''
              return [contact.nom, contact.prenom, `- (${contact.type})`].join(
                ' ',
              )
            },
          },
          {
            label: 'Adresse',
            value: (selectedRaf: SelectedRaf) => {
              const { adresse_rue, adresse_code_postal, adresse_ville } =
                selectedRaf
              return [adresse_rue, adresse_code_postal, adresse_ville].join(' ')
            },
          },
          {
            label: 'Tel portable',
            value: (selectedRaf: SelectedRaf) => {
              const { contact } = selectedRaf
              if (!contact.mobile) return ''
              return contact.mobile
            },
          },
          {
            label: 'Tel fixe',
            value: (selectedRaf: SelectedRaf) => {
              const { contact } = selectedRaf
              if (!contact.fixe) return ''
              return contact.fixe
            },
          },
          {
            label: 'Email',
            value: (selectedRaf: SelectedRaf) => {
              const { contact } = selectedRaf
              if (!contact.email) return ''
              return contact.email
            },
          },
          {
            label: 'Dernière intervention',
            value: (selectedRaf: SelectedRaf) => {
              const { last_intervention_date } = selectedRaf
              if (!last_intervention_date) return undefined
              return format(new Date(last_intervention_date), 'd MMMM yyyy', {
                locale: fr,
              })
            },
          },
          {
            label: 'Prix dernière intervention',
            value: (selectedRaf: SelectedRaf) => {
              const { prix } = selectedRaf
              if (!prix) return undefined
              return prix
            },
          },
        ],
        content: filteredRafList,
      },
    ]
    const exportDate = format(new Date(), 'dd MMMM yyyy', { locale: fr })
    const settings = {
      fileName: `Reste à faire - export ${exportDate}`, // Name of the spreadsheet
      extraLength: 0, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }
    xlsx(data, settings)
  }
  return (
    <div className={buttonWrapper}>
      <Button
        color="primary"
        disabled={loading || count === 0}
        onClick={exportToBeDone}
        classes={{ root: exportButton }}
      >
        Exporter sélection
      </Button>
      {loading && <CircularProgress size={24} className={buttonProgress} />}
    </div>
  )
}
