import Card from '@material-ui/core/Card'
import type { FullContactAdresse } from '@willig/types/api'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useList } from 'src/libs/useGetList'

import type { InterventionCreate } from 'src/types/api/extendedTypes'

import type { EntriesStatus } from './OtherRelationsActions'
import { OtherRelationActions } from './OtherRelationsActions'
import { SelectRelationship } from './SelectRelationShip'

type RelationshipsProps = {
  otherEntriesState: EntriesStatus
  setOtherEntriesState: (
    update: (entries: EntriesStatus) => EntriesStatus,
  ) => void
}

export const Relationships = (props: RelationshipsProps) => {
  const { otherEntriesState, setOtherEntriesState } = props
  const { values } = useFormState<InterventionCreate>({
    subscription: { values: true },
  })

  const showAdresssContactRelationship = Boolean(
    values.adresse_id && values.contact_id,
  )
  const showAdresssInvoiceContactRelationship = Boolean(
    values.adresse_id && values.facture_adresse_id,
  )

  const { data: residentRelationship } = useList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        contact_id: [values.contact_id],
        '!deleted': [1],
        adresse_id: [values.adresse_id],
      },
    },
    {
      enabled: Boolean(values.contact_id && values.adresse_id),
      suspense: true,
    },
  )

  const { data: invoicerRelationship } = useList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        contact_id: [values.facture_contact_id],
        '!deleted': [1],
        adresse_id: [values.adresse_id],
      },
    },
    {
      enabled: Boolean(values.facture_contact_id && values.adresse_id),
      suspense: true,
    },
  )

  const { invoice_relation_type, contact_relation_type } = values

  const relationShipError = useMemo(() => {
    const errors = []
    if (invoice_relation_type === contact_relation_type) {
      errors.push(
        `Il ne peut y avoir 2 relations de type ${contact_relation_type} sur une même adresse`,
      )
      return errors
    }
    if (
      invoicerRelationship &&
      invoicerRelationship.some((relation) => {
        return relation.type_relation! === (invoice_relation_type as any)
      })
    ) {
      errors.push(
        `Il existe déja une relation de type ${invoice_relation_type} sur cette adresse de facturation, cette relation ne sera pas créée`,
      )
    }
    return errors
  }, [contact_relation_type, invoice_relation_type, invoicerRelationship])

  return (
    <Card>
      {showAdresssContactRelationship && (
        <>
          <h3>Relations entre adresse et contact</h3>
          {residentRelationship.length > 1 && (
            <h6>
              plusieurs relation existent le champs ne peut pas être prérempli
            </h6>
          )}
          <SelectRelationship
            initialValue={
              residentRelationship.length === 1
                ? residentRelationship[0].type_relation
                : undefined
            }
            targetCommentType="contact_relation_comment"
            targetRelationType="contact_relation_type"
          />
          <OtherRelationActions
            adresse_id={values.adresse_id}
            contact_id={values.contact_id!}
            otherEntriesState={otherEntriesState}
            setOtherEntriesState={setOtherEntriesState}
          />
        </>
      )}
      {showAdresssInvoiceContactRelationship && (
        <>
          {invoicerRelationship.length > 1 && (
            <h6>
              plusieurs relation existent le champs ne peut pas être prérempli
            </h6>
          )}
          <h3>Relation entre adresse et contact de facturation</h3>
          <SelectRelationship
            initialValue={
              invoicerRelationship.length === 1
                ? invoicerRelationship[0].type_relation
                : undefined
            }
            targetCommentType="invoice_relation_comment"
            targetRelationType="invoice_relation_type"
            errors={relationShipError}
          />
        </>
      )}
    </Card>
  )
}
