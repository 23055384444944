import { Box } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import type { Adress } from '@willig/types/api'

import { useState } from 'react'
import { Button, useCreate, useNotify } from 'react-admin'
import { useFormState } from 'react-final-form'
import { SelectInput } from 'src/components/SelectInput'

import { useInvalidate } from 'src/hooks/useInvalidate'

import { useList } from '../../libs/useGetList'

const useStyles = makeStyles(() => {
  return {
    dialogContent: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      maxWidth: '800px',
    },
  }
})

export function AddContractModal() {
  const [modalState, toggleModal] = useState(false)

  const [contrat, setContrat] = useState()

  const { values } = useFormState<Adress>({ subscription: { values: true } })

  const handleClose = () => {
    toggleModal(false)
  }

  const notify = useNotify()

  const { data } = useList(
    'Contrat',
    {
      filter: {},
      pagination: { page: 1, perPage: 250 },
      sort: { field: 'name', order: 'ASC' },
    },
    { suspense: true },
  )

  const invalidate = useInvalidate()

  const { dialogContent } = useStyles()
  const [create] = useCreate<any>()

  const createRelationship = () => {
    const datas = {
      contrat_id: contrat,
      address_id: values.id,
      deleted: false,
    }
    create('ContratAddress', datas, {
      onSuccess() {
        notify("L'adresse a bien été ajoutée au contrat", 'success')
        invalidate('FullContratAddress')
        toggleModal(false)
      },
      onFailure() {
        notify('Une erreur est survenue', 'error')
        invalidate('FullContratAddress')
      },
    })
  }

  const options = data?.map((dt) => {
    return {
      value: dt.id,
      name: dt.name,
    }
  })

  if (!options) return null

  return (
    <Box display={'flex'} justifyContent={'flex-end'}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          toggleModal(true)
        }}
      >
        <>Ajouter un contrat </>
      </Button>
      <Dialog
        aria-label={'Ajouter un contrat'}
        open={modalState}
        onClose={handleClose}
      >
        <DialogTitle>{'Ajouter un contrat'}</DialogTitle>
        <DialogContent className={dialogContent}>
          <SelectInput
            label={'Contrat'}
            choices={options}
            onChange={(value) => {
              setContrat(value as any)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={createRelationship}>
            <>Enregistrer</>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
