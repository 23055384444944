import { Stack } from 'src/components/UI/Stack'

import {
  PeriodFilter,
  PresetsFilters,
  PaymentFilter,
  SinaoStatusFiter,
  SweepFilter,
} from './InvoiceFilters'

export function ParticularFilters(props: {
  onChange: () => (e?: React.BaseSyntheticEvent) => Promise<void>
}) {
  const { onChange } = props

  return (
    <Stack
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 8,
      }}
    >
      <Stack
        css={{
          display: 'flex',
          width: '100%',
          gap: 8,
        }}
      >
        <SweepFilter onChange={onChange} />
        <PaymentFilter onChange={onChange} />
        <PeriodFilter onChange={onChange} />
        <SinaoStatusFiter onChange={onChange} />
      </Stack>
      <Stack
        css={{
          display: 'flex',
          width: '100%',
          gap: 8,
        }}
      >
        <PresetsFilters onChange={onChange} />
      </Stack>
    </Stack>
  )
}
