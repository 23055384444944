import type { Theme } from '@material-ui/core'
import { Box, Toolbar, Typography, Modal } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  DeleteForever as DeleteForeverIcon,
  Mail as MailIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import type {
  EnumContactsAdressType,
  FullContactAdresse,
  FullAddress,
  ContactsAdress,
} from '@willig/types/api'
import { EnumFullContactAdresseTypeRelation } from '@willig/types/api'
import { useState } from 'react'
import type { EditProps, Identifier } from 'react-admin'
import {
  Edit,
  FormWithRedirect,
  SaveButton,
  useRedirect,
  useUpdate,
  useNotify,
  Button,
  List,
  Datagrid,
  TextField,
  FunctionField,
  BooleanField,
} from 'react-admin'
import { DefaultPagination } from 'src/components/defaultPagination'
import { SelectInput } from 'src/components/SelectInput'
import { convertEnumAsOptionWithValue } from 'src/libs/enumAsOptions'

import { MessagesHistory } from 'src/page/ResteAFaire/MessagesHistory'

import { InterventionList } from '../../components/InterventionList'
import { useInvalidate } from '../../hooks/useInvalidate'

import { AddRelationshipModal } from './AddRelationShipModal'
import { AnomaliesList } from './AnomaliesList'
import { ContractTable } from './ContractTable'
import { CreateEditInputs } from './CreateEditInputs'

const useStyles = makeStyles((theme: Theme) => {
  return {
    deleteButton: { color: theme.palette.error.main },
    historyModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      minWidth: theme.spacing(50),
      maxWidth: '30%',
      maxHeight: '80%',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }
})

type ParsedAddress = FullAddress & { anomalies: string[] }

type AnomaliesInfoProps = {
  record: ParsedAddress
}

export const EditView = (props: EditProps) => {
  const notify = useNotify()
  return (
    <Edit {...props} resource="Adress" mutationMode={'pessimistic'}>
      <FormWithRedirect
        {...props}
        redirect="edit"
        render={(renderProps) => {
          const { saving, handleSubmit, record } = renderProps
          return (
            <>
              <CreateEditInputs />
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    saving={saving}
                    handleSubmitWithRedirect={handleSubmit}
                    onSuccess={() =>
                      notify(
                        'Changements enregistrés',
                        'success',
                        {},
                        undefined,
                        2000,
                      )
                    }
                    onFailure={() =>
                      notify(
                        "Une erreur s'est produite",
                        'error',
                        {},
                        undefined,
                        2000,
                      )
                    }
                  />
                </Box>
              </Toolbar>

              <Box mt={8}>
                {record && <AddressInfos record={record as ParsedAddress} />}
              </Box>
            </>
          )
        }}
      />
    </Edit>
  )
}

const AddressInfos = (props: AnomaliesInfoProps) => {
  const { record } = props

  const redirect = useRedirect()

  const goToIntervention = (adress_id: Identifier | undefined) => {
    redirect(`/Intervention/create?adress_id=${adress_id}`)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
      <MuiButton
        fullWidth
        color="secondary"
        variant="contained"
        size="small"
        onClick={() => goToIntervention(record?.id)}
      >
        Ajouter une intervention
      </MuiButton>
      <Card>
        <CardContent>
          <InterventionList adresse_id={record?.id} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <AnomaliesInfo
            record={record as FullAddress & { anomalies: string[] }}
          />
        </CardContent>
      </Card>

      {record?.id && (
        <Card>
          <CardContent>
            <RelationTable addressId={record.id} />
          </CardContent>
        </Card>
      )}
      {record?.id && (
        <Card>
          <CardContent>
            <ContractTable addressId={record.id} />
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

function RelationTable(props: { addressId: string }) {
  return (
    <>
      <Typography variant={'overline'}>{'Contacts'}</Typography>
      <RelationList {...props} />
    </>
  )
}

function RelationList(props: { addressId: string }) {
  const { addressId } = props

  const notify = useNotify()

  const [update] = useUpdate<ContactsAdress>()

  const invalidate = useInvalidate()

  const handleRelationTypeChange = (
    id: number,
    value: EnumFullContactAdresseTypeRelation,
  ) => {
    update(
      'ContactsAdress',
      id,
      {
        type: value as unknown as EnumContactsAdressType,
      },
      {},
      {
        async onSuccess() {
          await invalidate('FullContactAdresse')
          notify('Relation mise à jour', 'success')
        },
        async onFailure() {
          await invalidate('FullContactAdresse')
          notify('Une erreur est survenue', 'error')
        },
      },
    )
  }

  const { deleteButton } = useStyles()

  const deleteContactAddress = (id: number) => {
    if (
      window.confirm(
        `La relation ${id} est sur le point d'être supprimée, continuer ? `,
      )
    ) {
      update(
        'ContactsAdress',
        id,
        { deleted: true },
        {},
        {
          async onSuccess() {
            await invalidate('FullContactAdresse')
            notify('Relation suprimée', 'success')
          },
          async onFailure() {
            await invalidate('FullContactAdresse')
            notify('Une erreur est survenue', 'error')
          },
        },
      )
    }
  }

  return (
    <List
      filter={{ adresse_id: addressId, '!deleted': [1] }}
      resource="FullContactAdresse"
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      actions={<AddRelationshipModal adresse_id={addressId} />}
      perPage={25}
      basePath="/"
      empty={false}
    >
      <Datagrid
        basePath="/"
        rowClick={(_id, _base, record) => {
          return `/Contact/${(record as FullContactAdresse).contact_id}`
        }}
      >
        <FunctionField<FullContactAdresse>
          label="Relation"
          sx={{ zIndex: '1000' }}
          onClick={(e) => e.stopPropagation()}
          render={(record) => {
            if (!record) return <></>
            return (
              <SelectRelation
                key={record.id + record.type_relation}
                record={record}
                onChange={handleRelationTypeChange}
              />
            )
          }}
        />
        <TextField source="raison_sociale" />
        <TextField source="nom" />
        <TextField source="prenom" />

        <TextField source="fixe" />
        <TextField source="mobile" />
        <BooleanField source="is_payor" label="Payeur" />
        <FunctionField<FullContactAdresse>
          onClick={(e) => e.stopPropagation()}
          render={(record) => {
            if (!record) return <></>
            return (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={() => deleteContactAddress(record.id)}
                  className={deleteButton}
                >
                  <>
                    <DeleteForeverIcon /> Supprimer
                  </>
                </Button>
                <ModalHistory contactId={record.contact_id} />
              </Box>
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

function SelectRelation(props: {
  record: FullContactAdresse
  onChange: (id: number, value: EnumFullContactAdresseTypeRelation) => void
}) {
  const { record, onChange } = props

  return (
    <SelectInput<EnumFullContactAdresseTypeRelation>
      label={'Relation'}
      initialState={record.type_relation}
      choices={
        convertEnumAsOptionWithValue(EnumFullContactAdresseTypeRelation) as any
      }
      onChange={(value) =>
        onChange(record.id, value as EnumFullContactAdresseTypeRelation)
      }
    />
  )
}

type ModalHistoryProps = {
  contactId: string
}

function ModalHistory(props: ModalHistoryProps) {
  const { contactId } = props
  const { historyModal, modal } = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  function openModal() {
    setIsOpen(true)
  }
  function handleClose() {
    setIsOpen(false)
  }
  return (
    <div>
      <Button onClick={() => openModal()}>
        <>
          <MailIcon /> Historique
        </>
      </Button>
      <Modal open={isOpen} onClose={handleClose} className={historyModal}>
        <div className={modal}>
          <MessagesHistory contactId={contactId} />
        </div>
      </Modal>
    </div>
  )
}

function AnomaliesInfo(props: AnomaliesInfoProps) {
  const { record } = props
  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
      <AnomaliesList address={record} />
    </Box>
  )
}
