import Box from '@material-ui/core/Box'
import { green, red } from '@material-ui/core/colors'
import type { RadioProps } from '@material-ui/core/Radio'
import Radio from '@material-ui/core/Radio'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import type { FullContactAdresse } from '@willig/types/api'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'
import { Boundary } from 'src/components/Boundary'
import { useList } from 'src/libs/useGetList'
import { theme } from 'src/UI/theme'

const useStyles = makeStyles({
  relationForm: {
    gap: '1rem',
    borderColor: theme.palette.primary.main,
  },
})

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: RadioProps) => {
  return <Radio color="default" {...props} />
})

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />)

type OtherRelationActionsProps = {
  adresse_id: string
  contact_id: string
  otherEntriesState: any
  setOtherEntriesState: (
    update: (entries: EntriesStatus) => EntriesStatus,
  ) => void
}

export type EntriesStatus = {
  [id: string]: 0 | 1 | 2
}
export const OtherRelationActions = (props: OtherRelationActionsProps) => {
  return (
    <Box>
      <Boundary>
        <OtherRelationActionsImpl {...props} />
      </Boundary>
    </Box>
  )
}

function OtherRelationActionsImpl(props: OtherRelationActionsProps) {
  const { adresse_id, contact_id, otherEntriesState, setOtherEntriesState } =
    props
  const classes = useStyles()

  const { data: otherEntryLinkedToContact } = useList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        contact_id: [contact_id],
        '!deleted': [1],
        '!adresse_id': [adresse_id],
      },
    },
    {
      suspense: true,
      enabled: Boolean(contact_id && adresse_id),
      onSuccess(response) {
        const otherEntryLinkedToThisContact = response
        if (isEmpty(otherEntryLinkedToThisContact)) return

        const cleanOtherEntries = Object.fromEntries(
          otherEntryLinkedToThisContact?.map((entry) => [
            entry.id,
            1 as const,
          ]) ?? [],
        )
        setOtherEntriesState(() => {
          return {
            ...cleanOtherEntries,
          }
        })
      },
    },
  )
  const updateStatus = (id: number | string, status: 0 | 1 | 2) => {
    setOtherEntriesState((entries: EntriesStatus): EntriesStatus => {
      return { ...entries, [id]: status }
    })
  }
  return (
    <>
      {otherEntryLinkedToContact?.map((e: FullContactAdresse) => (
        <Fragment key={e.id}>
          <h3>{`Autre adresse liée au contact ${e.nom} ${e.prenom}`}</h3>
          <Box
            display="flex"
            alignItems="baseline"
            border={1}
            borderRadius={4}
            px={2}
            py={2}
            className={classes.relationForm}
          >
            <Box
              alignSelf="center"
              flexGrow={1}
            >{`${e.nom} ${e.prenom} - ${e.rue} à ${e.ville} - ${e.type_relation}`}</Box>
            <Box display="flex" alignItems="center">
              <GreenRadio
                checked={otherEntriesState[e.id] === 1}
                onChange={() => updateStatus(e.id, 1)}
                value="1"
                id={`${e.id}-keep`}
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'C' }}
              />
              <label htmlFor={`${e.id}-keep`}>Conserver la relation</label>
            </Box>
            <Box display="flex" alignItems="center">
              <RedRadio
                checked={otherEntriesState[e.id] === 2}
                onChange={() => updateStatus(e.id, 2)}
                value="2"
                id={`${e.id}-delete`}
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'C' }}
              />
              <label htmlFor={`${e.id}-delete`}>Supprimer la relation</label>
            </Box>
          </Box>
        </Fragment>
      ))}
    </>
  )
}
