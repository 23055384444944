import type { StatusIntervention } from '@willig/types/api'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useUseQuery } from 'src/libs/useGetList'

export function useInterventionStatus(
  status: number,
  options?: UseQueryOptions,
) {
  const dataProvider = useDataProvider()
  const { data: interventionStatus } = useUseQuery({
    queryKey: ['StatusIntervention', status],
    queryFn: async () => {
      const { data } = await dataProvider.getOne<StatusIntervention>(
        'StatusIntervention',
        {
          id: status,
        },
      )
      return data as StatusIntervention
    },
    staleTime: Infinity,
    ...options,
  })

  return interventionStatus as StatusIntervention
}
