import { Typography, Box } from '@material-ui/core'
import type { Intervention } from '@willig/types/api'

import { useList } from '../libs/useGetList'

import { Boundary } from './Boundary'
import { InterventionCard } from './InterventionCard'

type InterventionListProps = {
  adresse_id: string | number
}

export const InterventionList = (props: InterventionListProps) => {
  return (
    <Box mt={2} display="flex" gridGap={16} flexDirection="column">
      <Typography variant="overline">Historique des interventions</Typography>
      <Boundary>
        <InterventionImpl {...props} />
      </Boundary>
    </Box>
  )
}

function InterventionImpl(props: InterventionListProps) {
  const { adresse_id } = props
  const { data: interventions } = useList<Intervention>(
    'Intervention',
    {
      filter: { adresse_id: [adresse_id] },
      pagination: { page: 0, perPage: 52 },
      sort: { order: 'DESC', field: 'rdv_date' },
    },
    { enabled: Boolean(adresse_id), suspense: true },
  )

  return (
    <>
      {!interventions?.length && <p>Aucun historique</p>}
      {interventions?.map((intervention) => (
        <Boundary>
          <InterventionCard key={intervention.id} intervention={intervention} />
        </Boundary>
      ))}
    </>
  )
}
