import { TextField } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons'
import type { User } from '@willig/types/api'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { Button, useNotify } from 'react-admin'

import { useList } from '../../libs/useGetList'

const useStyles = makeStyles((theme) => {
  return {
    agendaMenu: {
      display: 'flex',
      gap: `${theme.spacing(1)}px`,
    },
    sweepMenu: {
      border: `1px solid black`,
      borderRadius: `4px`,
      padding: `${theme.spacing(2)}px 0 `,
      display: 'flex',
      gridGap: `${theme.spacing(1)}px`,
      justifyContent: 'left',
      paddingLeft: theme.spacing(2),
      flexGrow: 1,
    },
    datePicker: {
      marginRight: theme.spacing(1),

      // border: `1px solid ${theme.palette.primary.main}`,
    },
    activeButton: {
      backgroundColor: theme.palette.primary.main,
    },
    button: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
    doubleArrow: {
      transform: 'scale(-1)',
    },
    toggleButton: {
      width: '250px',
      height: '58px',
    },
  }
})

type sweeps = { sweep_id: string; name: string }

type AgendaMenuProps = {
  contractListState: boolean
  setContractListState: Dispatch<SetStateAction<boolean>>
  specialEventTypesListState: boolean
  setSpecialEventTypesListState: Dispatch<SetStateAction<boolean>>
  selectedSweeps: sweeps[]
  setSelectedSweep: Dispatch<SetStateAction<sweeps[]>>
  initialDate: string
  setInitialDate: React.Dispatch<React.SetStateAction<string>>
}

export const AgendaMenu = (props: AgendaMenuProps) => {
  const {
    setContractListState,
    contractListState,
    specialEventTypesListState,
    setSpecialEventTypesListState,
    selectedSweeps,
    setSelectedSweep,
    initialDate,
    setInitialDate,
  } = props

  const {
    agendaMenu,
    sweepMenu,
    toggleButton,
    activeButton,
    button,
    doubleArrow,
    datePicker,
  } = useStyles()
  const notify = useNotify()

  const { data: sweeps } = useList<User>(
    'User',
    {
      filter: { '<access_level': [50], '>access_level': [9] },
      pagination: { page: 0, perPage: 0 },
      sort: { field: 'login', order: 'ASC' },
    },
    { suspense: true },
  )

  const toggleContractList = () => {
    setContractListState((previous) => !previous)
  }
  const toggleSpecialEventTypesList = () => {
    setSpecialEventTypesListState((previous) => !previous)
  }

  const selectSweep = (sweepProps: { sweep_id: string; name: string }) => {
    const { sweep_id, name } = sweepProps
    if (
      selectedSweeps.some(
        (selectedSweep) => selectedSweep.sweep_id === sweep_id,
      )
    ) {
      setSelectedSweep((previousSweeps) => [
        ...previousSweeps.filter(
          (previousSweep) => previousSweep.sweep_id !== sweep_id,
        ),
      ])
      return
    }
    if (selectedSweeps.length === 7) {
      notify('Veuillez deselectionner un calendrier')
      return
    }
    setSelectedSweep((previousSweeps) => {
      return [...previousSweeps, { sweep_id, name }]
    })
  }

  return (
    <div className={agendaMenu}>
      <MuiButton
        size="small"
        className={toggleButton}
        onClick={toggleContractList}
        color="secondary"
        variant="contained"
      >
        {contractListState ? (
          <DoubleArrowIcon className={doubleArrow} />
        ) : (
          'Afficher les contrats'
        )}
      </MuiButton>

      <MuiButton
        size="small"
        className={toggleButton}
        onClick={toggleSpecialEventTypesList}
        color="primary"
        variant="contained"
      >
        {specialEventTypesListState ? (
          <DoubleArrowIcon className={doubleArrow} />
        ) : (
          'Ajouter un évenement'
        )}
      </MuiButton>
      <div className={sweepMenu}>
        <TextField
          className={datePicker}
          id="date"
          label="Date"
          type="date"
          variant="outlined"
          value={initialDate}
          onChange={(event) => setInitialDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {sweeps?.map((sweep) => (
          <Button
            className={
              selectedSweeps.some(
                (selectedSweep) => selectedSweep.sweep_id === sweep.id,
              )
                ? activeButton
                : button
            }
            onClick={() =>
              selectSweep({ sweep_id: sweep.id, name: sweep.login })
            }
            variant="contained"
            key={sweep.id}
          >
            <span>{sweep.login}</span>
          </Button>
        ))}
      </div>
    </div>
  )
}
