// from sinimo
import { split, map, join, pipe, filter, toLower } from 'rambda'
import removeDiacritics from 'remove-diacritics'

export type Filter<T> = {
  [k: string]:
    | undefined
    | string
    | number
    | boolean
    | (string | number | boolean)[]
} & {
  // eslint-disable-next-line no-unused-vars
  [P in keyof T]?: string | number | boolean | (string | number | boolean)[]
}
export type FilterOr<T> = Filter<T> | Filter<T>[]
export type FilterAnd<T> = FilterOr<T> | Filter<T>

export type Filters<T> = FilterAnd<T>

export function encodeFilterParams<T extends {}>(
  params: Filters<T> = {},
): string[] | undefined {
  if (Array.isArray(params)) {
    return undefIfEmpty([
      params.map(encodeFilterParams).filter(Boolean).join(',or,'),
    ])
  }
  // ?filter[]=title,like,%Mandat%recherche%&filter[]=type,in,mandat de recherche,mandat de vente
  const filters = Object.entries(
    filter(
      (val) => {
        if (Array.isArray(val)) {
          return Boolean(val.length)
        }

        return val !== undefined && val !== ''
      }, // for now filter boolean values application side
      params,
    ),
  )
    .map(([column, query]) => {
      if (column === 'customFilter' && typeof query === 'string') {
        return query
      }
      if (Array.isArray(query) && query.length === 1) {
        return buildQueryParam(column, 'eq', query[0])
      }

      if (Array.isArray(query) && query.length > 1) {
        return URIin(
          column,
          query.filter((val) => val !== undefined && val !== ''),
        )
      }

      if (typeof query === 'boolean') {
        return URInullable(column, query)
      }

      return URIfuzzyLike(column, String(query))
    })
    .filter(Boolean)

  return undefIfEmpty(filters)
}

function undefIfEmpty<T>(array: T[]) {
  return array.length ? array : undefined
}

const prepareFuzzyQueryFromString = pipe(
  split(' '),
  filter((x: any) => Boolean(x)),
  map(toLower),
  map(removeDiacritics),
  map((x: string) => x.replace('%', '\\%')),
  join('%'),
  (str) => `%${str}%`,
)

type NullableParamString = string | 'null_'

export function URIfuzzyLike(column: string, likeString: NullableParamString) {
  if (likeString === 'null_') {
    return buildQueryParam(column, 'null_', 'null')
  }

  return buildQueryParam(
    column,
    'like',
    prepareFuzzyQueryFromString(likeString),
  )
}

function URIin(column: string, enumArray: (string | number | boolean)[]) {
  if (!enumArray.length) return ''

  return buildQueryParam(column, 'in', join(',', enumArray))
}

function URInullable(column: string, truthy: any) {
  return buildQueryParam(column, truthy ? 'nnull' : 'null')
}

const buildQueryParam = (
  column: string,
  verb: string,
  value?: number | string | boolean,
) => {
  if (verb === 'null_') {
    return `${[column, value].filter((x) => x !== undefined).join(',')}`
  }
  if (column.startsWith('!')) {
    verb = `n${verb}`
    column = column.replace(/^!/, '')
  }
  if (column.startsWith('<')) {
    verb = `lte`
    column = column.replace(/^</, '')
  }
  if (column.startsWith('>')) {
    verb = `gte`
    column = column.replace(/^>/, '')
  }

  return `${[column, verb, value].filter((x) => x !== undefined).join(',')}`
}
