import { useDataProvider, useRefresh } from 'react-admin'

export function useInvalidate() {
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  return async (resource: string) => {
    await dataProvider.getList(resource, {
      filter: {},
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'ASC' },
    })
    refresh()
  }
}
