import { useEnv } from 'src/adapters/env'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'

import { useUseQuery } from '../libs/useGetList'

type HistoryParams = {
  contactId?: string
  contactTel?: string
}

export function useSibHistory(params: HistoryParams) {
  const { contactId, contactTel } = params
  const env = useEnv()
  const nxHttpClient = useNxHttpClient()

  const { data: history } = useUseQuery<SibHistory[]>({
    queryKey: ['SIB', 'getHistory', params],
    queryFn: async () => {
      const jwt = localStorage.getItem('jwt')
      const sibUrl = env.SIB_URL
      const response = await nxHttpClient.get(`${sibUrl}/history`, {
        headers: {
          jwt,
          'x-api-key': env.SIB_API_KEY,
          'Content-Type': 'application/json',
        },
        params: {
          id: contactId,
          for: contactTel,
        },
      })
      return response.data
    },
    enabled: Boolean(contactId) || Boolean(contactTel),
  })

  return history ?? []
}

export type SibHistory = {
  id: number
  type: 'mail' | 'sms'
  subject: string
  current_state: 'sent' | 'duplicate'
  create_dt: number
  change_dt: number
  sib_id: string
}

export function useSibTemplates() {
  const env = useEnv()
  const jwt = localStorage.getItem('jwt')
  const nxHttpClient = useNxHttpClient()

  const { data: templates } = useUseQuery({
    queryKey: ['SIB', 'getTemplates'],
    queryFn: async () => {
      const sibUrl = env.SIB_URL
      const config = {
        headers: {
          jwt,
          'x-api-key': env.SIB_API_KEY,
          'Content-Type': 'application/json',
        },
      }
      const response = await nxHttpClient.get<string[]>(
        `${sibUrl}/templates`,
        config,
      )
      return response.data
    },
  })

  return templates ?? []
}

type sibTouchProps = {
  data: {
    to: string
    body: string
    type: 'tel' | 'sms' | 'email'
  }
}

export function useSibTouch(props: sibTouchProps) {
  const { data } = props
  const env = useEnv()
  const jwt = localStorage.getItem('jwt')
  const nxHttpClient = useNxHttpClient()
  const { to, body, type } = data
  nxHttpClient.post(
    `${env.SIB_URL}/touch`,
    {
      to, // 02dc9d82-d095-44aa-9e72-545dc1c90c3b id thomas salvi
      type,
      body,
    },
    {
      headers: {
        jwt,
        'x-api-key': env.SIB_API_KEY, // masterKey: 4e345b7c-7431-4a8d-bac5-7dd5357c6f41
        'Content-Type': 'application/json',
      },
    },
  )
}
