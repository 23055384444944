import { FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MuiTextField from '@material-ui/core/TextField'
import type { Price, FullCatalogOnlyPrice } from '@willig/types/api'
import { isEqualWith } from 'lodash'
import { useState } from 'react'
import type { ListProps } from 'react-admin'
import {
  FunctionField,
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  TextField,
  ExportButton,
  TopToolbar,
  BulkExportButton,
  useListContext,
} from 'react-admin'

import { money } from 'src/libs/money'

import { DefaultPagination } from '../../components/defaultPagination'
import { useContractStyle } from '../contrat/hooks/useContractStyle'

function ListActions() {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  )
}

type CatalogFilterProps = {
  name: string
  label: string
  onChange: (value: Record<string, string>) => void
}

function CatalogFilter(props: CatalogFilterProps) {
  const { label, name, onChange } = props
  return (
    <MuiTextField
      fullWidth
      variant="filled"
      label={label}
      onChange={(e) => {
        onChange({ [name]: e.target.value })
      }}
    />
  )
}

type CatalogFiltersProps = {
  defaultFilters?: Record<string, string>
  isEditable: boolean
  catalogId?: string
}

function CatalogFilters(props: CatalogFiltersProps) {
  const { defaultFilters, isEditable, catalogId } = props

  const { setFilters, filterValues } = useListContext()

  function onFilterChange(value: Record<string, string>) {
    setFilters({ ...filterValues, ...defaultFilters, ...value }, [])
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={'row'}
        justifyContent={'flex-start'}
        gridGap={20}
      >
        <CatalogFilter label="Nom" name="name" onChange={onFilterChange} />
        <CatalogFilter label="Type" name="type" onChange={onFilterChange} />
        <CatalogFilter label="Mode" name="mode" onChange={onFilterChange} />
        <CatalogFilter
          label="Combustible"
          name="gas"
          onChange={onFilterChange}
        />
        {isEditable && catalogId && (
          <FormGroup
            style={{
              minWidth: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    if (isChecked) {
                      setFilters(
                        { ...filterValues, contrat_id: [catalogId] },
                        [],
                      )
                    } else {
                      setFilters(
                        {
                          ...filterValues,
                          contrat_id: undefined,
                          ...defaultFilters,
                        },
                        [],
                      )
                    }
                  }}
                />
              }
              label="tarifs négociés"
            />
          </FormGroup>
        )}
      </Box>
    </>
  )
}

type ExtendedListProps = ListProps & {
  isEditable?: boolean
  onCatalogAdd?: (value: any) => void
  extraFilters?: Record<string, any>
  catalogId?: string
}

export const ListView = (props: ExtendedListProps) => {
  const {
    isEditable = false,
    onCatalogAdd,
    extraFilters,
    catalogId,
    ...listProps
  } = props

  const defaultFilter = isEditable
    ? extraFilters
    : {
        contrat_id: 'null_',
      }

  return (
    <List
      {...listProps}
      filterDefaultValues={defaultFilter}
      filters={
        <CatalogFilters
          defaultFilters={defaultFilter}
          isEditable={isEditable}
          catalogId={catalogId}
        />
      }
      bulkActionButtons={!isEditable ? <BulkExportButton /> : false}
      actions={!isEditable && <ListActions />}
      pagination={<DefaultPagination />}
      perPage={25}
      component={isEditable ? 'div' : undefined}
    >
      <Datagrid rowClick={isEditable ? undefined : 'edit'}>
        <TextField source="type" />
        <TextField source="mode" />
        <TextField source="name" label="Nom" />

        <ReferenceField
          label="Appareil"
          source="appareil_id"
          reference="Appareil"
          linkType={!isEditable}
        >
          <TextField source="label" />
        </ReferenceField>
        <TextField source="gas" label="Famille du combustible" />
        <PriceColumn isEditable={isEditable} onCatalogAdd={onCatalogAdd} />

        <BooleanField source="collective" />
        <TextField source="extra" />
      </Datagrid>
    </List>
  )
}

function PriceColumn(props: {
  isEditable?: boolean
  onCatalogAdd?: (value: any) => void
}) {
  const { isEditable = false, onCatalogAdd } = props
  if (!isEditable) {
    return (
      <ReferenceField
        label="Prix (simple, medium, hard)"
        source="price"
        reference="Price"
      >
        <FunctionField
          render={(record?: unknown) => {
            const price = record as Price | undefined
            if (!price) {
              return ''
            }

            return (
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                textAlign="right"
              >
                <span>
                  {price.price_simple ? money(price?.price_simple) : '-'}
                </span>
                <span>
                  {price.price_medium ? money(price?.price_medium) : '-'}
                </span>
                <span>{price.price_hard ? money(price?.price_hard) : '-'}</span>
              </Box>
            )
          }}
        />
      </ReferenceField>
    )
  }
  return <EditablePrices onCatalogAdd={onCatalogAdd} />
}

function EditablePrices(props: { onCatalogAdd?: (value: any) => void }) {
  return (
    <FunctionField
      render={(record: any) => {
        return <EditablePricesImpl record={record} {...props} />
      }}
    />
  )
}

type LocalValue = Record<
  'price_simple' | 'price_medium' | 'price_hard',
  number | undefined
>

function EditablePricesImpl(props: {
  record: FullCatalogOnlyPrice
  onCatalogAdd?: (value: any) => void
}) {
  const { onCatalogAdd, record } = props
  const [localValue, setLocalValue] = useState<LocalValue>({
    price_simple: record?.price_simple,
    price_medium: record?.price_medium,
    price_hard: record?.price_hard,
  })

  const { customRow } = useContractStyle()

  const { contrat_id } = record

  function customizer(objValue: FullCatalogOnlyPrice, othValue: LocalValue) {
    if (contrat_id) return false
    if (
      objValue.price_simple !== othValue.price_simple ||
      objValue.price_medium !== othValue.price_medium ||
      objValue.price_hard !== othValue.price_hard
    ) {
      return false
    }
    return true
  }

  function getInputProps() {
    if (!isEqualWith(record, localValue, customizer))
      return { className: customRow }
  }

  return (
    <Box
      style={{
        display: 'flex',
        gap: 20,
        justifyContent: 'flex-start',
      }}
    >
      <MuiTextField
        type="number"
        value={localValue.price_simple}
        InputProps={{
          ...getInputProps(),
          style: { paddingLeft: 10 },
        }}
        onChange={(e) => {
          const { value } = e.target
          setLocalValue({ ...localValue, price_simple: Number(value) })
          onCatalogAdd?.({
            id: record?.id,
            price_simple: parseFloat(Number(value)?.toFixed(2)),
            price_medium: parseFloat(
              Number(localValue.price_medium)?.toFixed(2),
            ),
            price_hard: parseFloat(Number(localValue.price_hard)?.toFixed(2)),
          })
        }}
      />
      <MuiTextField
        type="number"
        value={localValue.price_medium}
        InputProps={{
          ...getInputProps(),
          style: { paddingLeft: 10 },
        }}
        onChange={(e) => {
          const { value } = e.target
          setLocalValue({ ...localValue, price_medium: Number(value) })
          onCatalogAdd?.({
            id: record?.id,
            price_simple: parseFloat(
              Number(localValue.price_simple)?.toFixed(2),
            ),
            price_medium: parseFloat(Number(value)?.toFixed(2)),
            price_hard: parseFloat(Number(localValue.price_hard)?.toFixed(2)),
          })
        }}
      />
      <MuiTextField
        type="number"
        value={localValue.price_hard}
        InputProps={{
          ...getInputProps(),
          style: { paddingLeft: 10 },
        }}
        onChange={(e) => {
          const { value } = e.target
          setLocalValue({ ...localValue, price_hard: Number(value) })
          onCatalogAdd?.({
            id: record?.id,
            price_simple: parseFloat(
              Number(localValue.price_simple)?.toFixed(2),
            ),
            price_medium: parseFloat(
              Number(localValue.price_medium)?.toFixed(2),
            ),
            price_hard: parseFloat(Number(value)?.toFixed(2)),
          })
        }}
      />
    </Box>
  )
}
