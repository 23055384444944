import { format, isValid } from 'date-fns'

import type { ApiFilters, LocalFilter } from './types'

export function encodeApiFilters(filters: Partial<LocalFilter>): ApiFilters {
  return encodeFilters(filters)
}

function encodeFilters(filters: Partial<LocalFilter>) {
  const { startDate, endDate, isPro } = filters
  if (!startDate || !endDate) return { isPro: false }
  if (!isValid(new Date(startDate)) || !isValid(new Date(endDate))) {
    return { isPro }
  }
  const encodedDates = encodeDates(new Date(startDate), new Date(endDate))

  const { encodedStartDate, encodedEndDate } = encodedDates

  return {
    isPro,
    startDate: encodedStartDate,
    endDate: encodedEndDate,
  }
}

function encodeDates(startDate: Date, endDate: Date) {
  return {
    encodedStartDate: format(startDate, 'yyyy-MM-dd 00:00:01'),
    encodedEndDate: format(endDate, 'yyyy-MM-dd 23:59:59'),
  }
}
