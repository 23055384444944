import { Box } from '@material-ui/core'
import type { Contrat } from '@willig/types/api'
import type { CreateProps } from 'react-admin'
import { SimpleForm, Create } from 'react-admin'

import { CatalogListContent } from './components/catalogList'
import { CreateEditForm } from './CreateEditForm'

const validateProCreation = (values: Contrat) => {
  const errors: any = {}

  if (!values.invoice_contact_id) {
    // eslint-disable-next-line functional/immutable-data
    errors.invoice_contact_id = 'Veuillez choisir un contact de facturation'
  }
  if (!values.invoice_address_id) {
    // eslint-disable-next-line functional/immutable-data
    errors.invoice_address_id = 'Veuillez choisir une adresse de facturation'
  }

  return errors
}

export const CreateView = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateProCreation} redirect={'edit'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gridGap={16}
          sx={{ width: '100%' }}
        >
          <CreateEditForm />
          <CatalogListContent />
        </Box>
      </SimpleForm>
    </Create>
  )
}
