import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import type { Adress, Contact, Contrat } from '@willig/types/api'
import {
  SelectInput,
  DateInput,
  TextInput,
  required,
  ReferenceInput,
  useGetOne,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { TypesenseInput } from 'src/components/Typesense/TypesenseInput'
import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'

import { EnumContratType } from '../../../../../packages/types/src/api/index'

import { useContractStyle } from './hooks'

export function CreateEditForm() {
  const { paper } = useContractStyle()

  const { values } = useFormState<Partial<Contrat>>({
    subscription: { values: true },
  }) // current values of every field in the form

  const { data: invoiceContact } = useGetOne<Contact>(
    'Contact',
    values.invoice_contact_id ?? '',
    {
      enabled: Boolean(values?.invoice_contact_id),
    },
  )
  const { data: invoiceAddress } = useGetOne<Adress>(
    'ZonesAdress',
    values.invoice_address_id ?? '',
    {
      enabled: Boolean(values?.invoice_address_id),
    },
  )

  return (
    <Box className={paper}>
      <Typography variant={'h6'}>{'Informations'}</Typography>
      <TextInput
        fullWidth
        source="name"
        label="Nom du contrat"
        validate={required()}
      />
      <DateInput
        fullWidth
        source="end_date"
        label="Date de fin de contrat"
        validate={required()}
      />

      <SelectInput
        fullWidth
        label={'Type de contrat'}
        choices={convertEnumAsOptionWithId(EnumContratType)}
        source={'type'}
        validate={required()}
      />
      <ReferenceInput reference="TypeTva" source="tva" label="TVA" fullWidth>
        <SelectInput optionText="label" validate={required()} />
      </ReferenceInput>
      <Box
        display="flex"
        flexDirection="row"
        gridGap={24}
        width={'100% !important'}
        marginBottom={2}
      >
        <Box display="flex" flexDirection="column" width="50%">
          <TypesenseInput
            source="invoice_contact_id"
            data={invoiceContact}
            indexName="contacts"
            inputLabel="Contact de facturation"
            emptyInputArray={['invoice_contact_id']}
            required
          />
          {invoiceContact && (
            <Card variant="outlined">
              <CardContent>
                <SimpleShowLayout record={invoiceContact}>
                  <TextField fullWidth source="raison_sociale" />
                  <TextField fullWidth source="nom" />
                  <TextField fullWidth source="prenom" />
                  <TextField fullWidth source="mobile" />
                  <TextField fullWidth source="fixe" />
                  <TextField fullWidth source="email" />
                  <TextField fullWidth source="commentaire" />
                </SimpleShowLayout>
              </CardContent>
            </Card>
          )}
        </Box>
        <Box display="flex" flexDirection="column" width="50%">
          <TypesenseInput
            source="invoice_address_id"
            data={invoiceAddress}
            indexName="adresses"
            inputLabel="Adresse de facturation"
            emptyInputArray={['invoice_address_id']}
            required
          />
          {invoiceAddress && (
            <Card variant="outlined">
              <CardContent>
                <SimpleShowLayout record={invoiceAddress}>
                  <TextField source="rue" />
                  <TextField source="code_postal" />
                  <TextField source="ville" />
                  <TextField source="cote" label="Intitulé d'adresse" />
                  <TextField source="etage" />
                  <TextField source="commentaire" />
                  <TextField label="zone" source="zone_label" />
                </SimpleShowLayout>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  )
}
