// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { TextInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useEnv } from 'src/adapters/env'

type CountryType = {
  label: string
  ville: string
  ancienne_ville: string
  code_postal: string
  rue: string
  lon: string
  lat: string
}

type GeoApiGouvProps = {
  source: string
  label: string
  required?: boolean
}

export default function GeoApiGouv(props: GeoApiGouvProps) {
  const { source, label, required = false } = props
  const [focused, setFocused] = useState<boolean>(false)

  return (
    <>
      <TextInput
        autoComplete="none"
        fullWidth
        source={source}
        label={label}
        onFocus={() => setFocused(true)}
        onBlur={() => setTimeout(() => setFocused(false), 200)}
        required={required}
        validate={(value?: string) => {
          if (required && !value) {
            return 'requis'
          }
        }}
      />
      {focused && <Suggestions />}
    </>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    box: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      padding: `0 0 0 ${theme.spacing(2)}px`,
    },
    item: {
      cursor: 'pointer',
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }
})

const Suggestions = () => {
  const classes = useStyles()
  const env = useEnv()
  const form = useForm()
  const [options, setOptions] = React.useState<CountryType[]>([])
  const { values } = useFormState({ subscription: { values: true } })

  const fetchAdresse = useCallback(
    async (query: string) => {
      if (query) {
        const response = await axios.get(
          `${env.API_URL}/GeoApiGouv.php?type=housenumber&lon=7.32&lat=47.77&q=${query}`,
        )
        const adresses = await response.data
        const cleanAdresse = adresses.features.map((e: any) => {
          const infos = e.properties
          const { geometry } = e
          return {
            label: infos.label,
            ville: infos.city,
            ancienne_ville: infos.oldcity,
            code_postal: infos.postcode,
            rue: infos.name,
            lon: geometry.coordinates[0],
            lat: geometry.coordinates[1],
          }
        })
        setOptions(cleanAdresse)
      }
    },
    [env.API_URL],
  )

  const fetchZone = async (lon: string, lat: string) => {
    const response = await axios.get(
      ` ${env.ZONE_URL}/zones?lat=${lat}&lon=${lon}&radius=1`,
    )
    const zone = await response.data
    if (zone[0] === undefined) return 1000
    return zone[0].properties.id
  }

  const selectAdresse = async (adresse: CountryType) => {
    const zone = await fetchZone(adresse.lon, adresse.lat)
    form.change('rue', adresse.rue)
    form.change('ville', adresse.ville)
    form.change('ancienne_ville', adresse.ancienne_ville)
    form.change('code_postal', adresse.code_postal)
    form.change('zone', zone)
    form.change('lat', adresse.lat)
    form.change('lon', adresse.lon)
  }
  useEffect(() => {
    fetchAdresse(values.rue)
  }, [values.rue, fetchAdresse])
  return (
    <Box className={classes.box}>
      {!isEmpty(options) ? (
        options.map((e) => (
          <p
            className={classes.item}
            onClick={() => selectAdresse(e)}
            key={e.label}
          >
            {e.label}
          </p>
        ))
      ) : (
        <p>aucune adresse trouvée</p>
      )}
    </Box>
  )
}
