import { Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import { format } from 'date-fns'
import { sortBy, splitAt } from 'rambda'
import { useForm } from 'react-final-form'
import { useFormContext, useWatch } from 'react-hook-form'
import { Stack } from 'src/components/UI/Stack'

import type { FreeSlotResponse } from '../../../libs/interventionQueryBuilder'

import type { ModalFormValues } from './types'

type FreeSlotProps = {
  freeSlots?: FreeSlotResponse[]
  onClose: () => void
}
export function FreeSlotProposal(props: FreeSlotProps) {
  const { freeSlots, onClose } = props

  const { setValue } = useFormContext()

  const parentForm: FreeSlotResponse = useWatch({
    name: 'parentForm',
  })

  const duration: ModalFormValues['duration'] = useWatch({
    name: 'duration',
  })

  const form = useForm()

  function populateForm(values: FreeSlotResponse) {
    const fullDate = new Date(values.rdv_date + 'T' + values.start_time)

    form.change('sweep_id', values.sweep_id)
    form.change('rdv_date', fullDate)
    form.change('rdv_time', values.start_time)
    form.change('duration', duration)
    onClose()
  }

  if (!freeSlots || !freeSlots.length) {
    return <Typography>Pas de disponibilités</Typography>
  }

  const sortedResults = sortByDistance(freeSlots)

  return (
    <Stack css={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Stack css={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        <Typography style={{ fontWeight: 'bold' }}>
          RDV le plus rapide (1er créneau libre)
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={freeSlots[0].id === parentForm?.id}
              value={freeSlots[0].id}
              onChange={() => {
                if (freeSlots[0].id === parentForm?.id) {
                  setValue('parentForm', undefined)
                } else {
                  setValue('parentForm', freeSlots[0])
                }
              }}
            />
          }
          label={getLabel(freeSlots[0])}
        />
      </Stack>

      <Stack css={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        <Typography style={{ fontWeight: 'bold' }}>
          RDV les plus proches (distance)
        </Typography>
        {sortedResults?.map((freeSlot) => {
          const isChecked = parentForm?.id === freeSlot.id
          return (
            <FormControlLabel
              key={freeSlot.id}
              control={
                <Checkbox
                  checked={isChecked}
                  value={freeSlot.id}
                  onChange={() => {
                    if (isChecked) {
                      setValue('parentForm', undefined)
                    } else {
                      setValue('parentForm', freeSlot)
                    }
                  }}
                />
              }
              label={getLabel(freeSlot)}
            />
          )
        })}
      </Stack>

      <MuiButton
        fullWidth
        color="secondary"
        variant="contained"
        size="small"
        disabled={!parentForm}
        onClick={() => populateForm(parentForm)}
      >
        Valider rdv
      </MuiButton>
    </Stack>
  )
}

function sortByDistance(freeSlots: FreeSlotResponse[]) {
  const sorted = sortBy<FreeSlotResponse>((x) => Number(x.distance), freeSlots)
  return splitAt(5, sorted)[0]
}

function getLabel(freeSlot: FreeSlotResponse) {
  const { distance, login, rdv_date, start_time } = freeSlot

  const formattedDate = format(
    new Date(rdv_date + 'T' + start_time),
    "dd/MM/yy - HH'h'mm",
  )

  const formattedDistance = `${Number(distance)} km`

  return [formattedDate, login, formattedDistance].filter(Boolean).join(' - ')
}
