import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useCallback } from 'react'
import { required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import { Boundary } from 'src/components/Boundary'

import { BillingAdress } from './BillingAdresse'
import ContactReferenceInput from './ContactReferenceInput'

type InvoiceContactInfoProps = {
  setSearchFacture: Dispatch<SetStateAction<boolean>>
  searchFacture: boolean
  isDisabled: boolean
}
export const InvoiceContactInfo = (props: InvoiceContactInfoProps) => {
  const { setSearchFacture, searchFacture, isDisabled = false } = props
  const { values } = useFormState({ subscription: { values: true } })
  const form = useForm()

  useEffect(() => {
    if (!values.facture_contact_id) {
      form.change('facture_adresse_id', null)
      setSearchFacture(false)
    } else {
      form.change('isOtherBillingContact', true)
    }
  }, [values.facture_contact_id, setSearchFacture, form])
  const RequiredUseMemo = useMemo(() => required(), [])

  const filterToQuery = useCallback((searchText: string) => {
    return {
      nom: searchText,
    }
  }, [])
  return (
    <Boundary>
      <ContactReferenceInput
        isDisabled={isDisabled}
        inputLabel="Contact de facturation"
        target="facture_contact_id"
        source="facture_contact_id"
        reference="Contact"
        validate={RequiredUseMemo}
        perPage={10}
        filterToQuery={filterToQuery}
        emptyContactInput={['facture_contact_id']}
      />
      <BillingAdress
        searchFacture={searchFacture}
        setSearchFacture={setSearchFacture}
      />
    </Boundary>
  )
}
