import type { Contrat } from '@willig/types/api'
import { Picker } from 'src/components/Form'
import { Stack } from 'src/components/UI/Stack'
import { useList } from 'src/libs/useGetList'

import { Boundary } from '../../../components/Boundary'

import { PeriodFilter, PresetsFilters } from './InvoiceFilters'

type Props = { onChange: () => (e?: React.BaseSyntheticEvent) => Promise<void> }

export function ProFilters(props: Props) {
  return (
    <Boundary>
      <Stack
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: 8,
        }}
      >
        <Stack
          css={{
            display: 'flex',
            width: '100%',
            gap: 8,
          }}
        >
          <ContractFilter {...props} />
          <PeriodFilter {...props} />
        </Stack>
        <Stack
          css={{
            display: 'flex',
            width: '100%',
            gap: 8,
          }}
        >
          <PresetsFilters {...props} />
        </Stack>
      </Stack>
    </Boundary>
  )
}

function ContractFilter(props: Props) {
  const { onChange } = props
  const { data: pros } = useList<Contrat>(
    'Contrat',
    {
      pagination: { page: 0, perPage: 0 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    },
    { suspense: true },
  )

  return (
    <Picker
      options={
        pros?.map((pro) => {
          return {
            value: pro.id,
            label: pro.name,
          }
        }) ?? []
      }
      name="contrat_id"
      label="Contrat"
      onChange={() => onChange()()}
    />
  )
}
