import type { SinaoStatusEnum } from '../../types/api/extendedTypes'

export type PaymentType = 'especes' | 'cheque' | 'CB' | 'impayé' | 'payé'

export type ApiFilters = {
  isPro?: Boolean
  startDate?: string
  endDate?: string
}

export type ProFilters = {
  contrat_id: string
}

export type ParticularFilter = {
  ramoneur?: string
  type_paiement?: PaymentType
  sinaoStatus?: SinaoStatusEnum
}

export type LocalFilter = ApiFilters & ProFilters & ParticularFilter

export type InvoiceErrorCodes =
  | 'invoiceAsNull'
  | 'emptyInstallationsLines'
  | 'installationsNotExists'
  | 'nameIsEmpty'
  | 'hasZohoInvoice'
  | 'hasSinaoInvoice'
  | 'socialNameIsEmpty'

export const InvalidReasonMap: Record<InvoiceErrorCodes, string> = {
  invoiceAsNull: 'Pas de prix TTC',
  emptyInstallationsLines: "Pas de lignes sur l'installations",
  installationsNotExists: "L'installation n'existe pas",
  nameIsEmpty: "Le nom n'est pas rempli",
  hasZohoInvoice: 'Voir dans ZOHO Invoice',
  hasSinaoInvoice: 'Déjà envoyée dans SINAO',
  socialNameIsEmpty: "La raison sociale n'est pas remplie",
}
