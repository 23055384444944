import { Box, CircularProgress, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Delete as DeleteIcon } from '@material-ui/icons'
import type {
  Contact,
  FullIntervention,
  FullSpecialEvent,
  Intervention,
  SpecialEvent,
} from '@willig/types/api'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import type { Identifier } from 'react-admin'
import { useGetOne, useNotify, useUpdate } from 'react-admin'
import type {
  FieldValues,
  UseFormGetValues,
  UseFormReset,
} from 'react-hook-form'
import {
  FormProvider,
  useForm,
  Controller,
  useFormContext,
} from 'react-hook-form'
import { useQueryClient } from 'react-query'

import { concatNames } from 'src/libs/concatNames'

import type { SelectedEvent } from './AgendaBody'

// test

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '2px solid black',
      margin: `${theme.spacing(1)}px 0 0 0`,
      padding: `${theme.spacing(1)}px 0`,
    },
    button: {
      margin: `${theme.spacing(1)}px 0`,
    },
    deleteButton: {
      margin: `${theme.spacing(1)}px 0`,
      backgroundColor: theme.palette.error.main,
    },
    updateButton: {
      margin: `${theme.spacing(1)}px 0`,
      backgroundColor: theme.palette.success.main,
    },
  }
})

type DetailModalProps = {
  toggleModal: Dispatch<SetStateAction<boolean>>
  modalState: boolean
  selectedEvent: SelectedEvent
  toggleDeleteModal: Dispatch<SetStateAction<boolean>>
  refetch: () => void
}
export function DetailModal(props: DetailModalProps) {
  const { modalState, toggleModal, selectedEvent, toggleDeleteModal, refetch } =
    props
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const {
    data,
    loading,
    refetch: refetchOne,
  } = useGetOne<FullIntervention | FullSpecialEvent>(
    `${
      selectedEvent?.type === 'Intervention'
        ? 'FullInterventionsWithoutTime'
        : 'FullSpecialEvent'
    }`,
    selectedEvent?.id as Identifier,
    { enabled: Boolean(selectedEvent) },
  )

  const handleClose = () => {
    toggleModal(false)
  }
  const handleDeleteModalOpen = () => {
    toggleDeleteModal(true)
  }

  const Body = (bodyProps: any) => {
    const { control } = bodyProps

    if (loading) {
      return <CircularProgress />
    }
    if (data && selectedEvent?.type === 'Intervention') {
      const {
        contact_nom,
        contact_prenom,
        contact_mobile,
        contact_fixe,
        facture_nom,
        facture_prenom,
        facture_mobile,
        facture_fixe,
        prerequisites,
        infos,
        int_ville,
        int_rue,
        int_code_postal,
        contact_raison_sociale,
        facture_raison_sociale,
      } = data as FullIntervention

      const contactFullName = concatNames({
        nom: contact_nom,
        prenom: contact_prenom,
        raison_sociale: contact_raison_sociale,
      } as Contact)

      const factureFullName = concatNames({
        nom: facture_nom,
        prenom: facture_prenom,
        raison_sociale: facture_raison_sociale,
      } as Contact)

      return (
        <dl>
          <ListElement
            label={'Client'}
            value={contactFullName ?? factureFullName}
          />

          <ListElement
            label={'Numero fixe'}
            value={contact_fixe ?? facture_fixe}
          />
          <ListElement
            label={'Numero portable'}
            value={contact_mobile ?? facture_mobile}
          />
          <ListElement
            label={'Adresse'}
            value={`${int_rue} ${int_code_postal} ${int_ville}`}
          />
          <ListElement label={'Prérequis'} value={prerequisites} />
          <ListElement label={'Informations'} value={infos} />
        </dl>
      )
    }
    if (data && selectedEvent?.type === 'SpecialEvent') {
      const { description, label } = data as FullSpecialEvent

      return (
        <dl>
          <Controller
            name="description"
            control={control}
            rules={{ required: true, minLength: 1 }}
            render={(renderProps) => {
              const { field } = renderProps
              return (
                <TextField
                  style={{ marginBottom: '24px' }}
                  required
                  multiline
                  fullWidth
                  variant="outlined"
                  label="Description"
                  defaultValue={description ?? label}
                  {...field}
                />
              )
            }}
          />
        </dl>
      )
    }
    return <p>Erreur de chargement</p>
  }

  const [update] = useUpdate<Intervention | SpecialEvent>()
  const queryClient = useQueryClient()
  const notify = useNotify()

  const handleUpdate = (
    getValues: UseFormGetValues<FieldValues>,
    reset: UseFormReset<FieldValues>,
  ) => {
    const description = getValues(`description`)
    if (!selectedEvent) {
      notify("Erreur lors de l'enregistrement de la description", 'error')
      return
    }
    update(
      {
        resource: selectedEvent.type,
        payload: {
          id: selectedEvent.id,
          data: { ...selectedEvent, description: description },
        },
      },
      {
        async onSuccess() {
          toggleDeleteModal(false)
          toggleModal(false)
          notify('Changement de la description', 'success')
          reset()
          await queryClient.invalidateQueries()
          refetch()
          refetchOne()
        },
      },
    )
  }

  const methods = useForm({
    defaultValues: {
      description: undefined,
    },
  })
  const { control } = methods

  return (
    <FormProvider {...methods}>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalState}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Body control={control} />
          <div className={classes.modalFooter}>
            {selectedEvent?.type === 'Intervention' && (
              <Button
                href={`/#/Intervention/${selectedEvent?.id}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Voir
              </Button>
            )}

            <Box display="flex" flexDirection="column">
              <Button
                fullWidth={false}
                onClick={handleDeleteModalOpen}
                variant="contained"
                color="secondary"
                className={classes.deleteButton}
                startIcon={<DeleteIcon />}
                disabled={Boolean(selectedEvent.sinaoId)}
              >
                <>Supprimer</>
              </Button>
              {selectedEvent.sinaoId && (
                <Typography color="error">
                  Déja envoyé en facturation
                </Typography>
              )}
            </Box>

            {selectedEvent?.type === 'SpecialEvent' && (
              <SaveButton handleUpdate={handleUpdate} />
            )}
          </div>
        </div>
      </Modal>
    </FormProvider>
  )
}

function SaveButton(props: any) {
  const { handleUpdate } = props
  const { getValues, reset } = useFormContext()

  return (
    <Button
      onClick={() => handleUpdate(getValues, reset)}
      variant="contained"
      color="primary"
    >
      Enregistrer
    </Button>
  )
}

type ListElementProps = {
  label: string
  value?: string
}
function ListElement(props: ListElementProps) {
  const { label, value } = props

  const formatedValue = formatValue(value)

  return (
    <>
      <Typography variant="h6" color="primary">
        {label}
      </Typography>
      <Typography variant="body2" style={{ margin: '0 0 16px 0' }}>
        {formatedValue}
      </Typography>
    </>
  )
}

function formatValue(value: string | number | undefined) {
  if ((typeof value === 'string' && value === 'null null') || !value) {
    return 'non renseigné'
  }
  if (typeof value === 'string' && value.includes('null')) {
    return value.replaceAll('null', '')
  }
  return value
}

type ConfirmDeleteModalProps = {
  toggleDeleteModal: Dispatch<SetStateAction<boolean>>
  deleteModalState: boolean
  selectedEvent: SelectedEvent
  refetch: () => void

  toggleModal: Dispatch<SetStateAction<boolean>>
}
export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const {
    deleteModalState,
    toggleDeleteModal,
    selectedEvent,
    toggleModal,
    refetch,
  } = props
  const queryClient = useQueryClient()
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const handleClose = () => {
    toggleDeleteModal(false)
  }
  const notify = useNotify()
  const [update] = useUpdate<Intervention | SpecialEvent>()

  const handleDeletion = () => {
    if (!selectedEvent) {
      notify("Erreur lors de la suppression de l'evenement", 'error')
      return
    }
    update(
      {
        resource: selectedEvent.type,
        payload: { id: selectedEvent.id, data: { deleted: true } },
      },
      {
        async onSuccess() {
          toggleDeleteModal(false)
          toggleModal(false)
          notify('Suppression effectuée', 'success')
          await queryClient.invalidateQueries()
          refetch()
        },
      },
    )
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={deleteModalState}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <h2>Êtes-vous certain de vouloir supprimer cette intervention ?</h2>
        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeletion}
            className={classes.deleteButton}
            startIcon={<DeleteIcon />}
          >
            Supprimer definitivement
          </Button>
        </div>
      </div>
    </Modal>
  )
}
