import { Typography, Box } from '@material-ui/core'

import { isString } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { useForm, useFormState } from 'react-final-form'

import { ListView } from '../../catalog/List'
import { useContractStyle } from '../hooks/useContractStyle'
import type { ContractCatalog, ExtendedContrat } from '../types'

export function CatalogListContent() {
  const { paper } = useContractStyle()

  const { values } = useFormState<ExtendedContrat>({
    subscription: { values: true },
  })

  const { catalog, id } = values

  const parsedCatalog: ContractCatalog[] = isString(catalog)
    ? JSON.parse(catalog)
    : catalog

  const contractsRecord = catalog
    ? parsedCatalog?.reduce((acc, cat) => {
        if (cat.id !== undefined) {
          acc[cat.id] = cat
        }
        return acc
      }, {} as Record<string, ContractCatalog>)
    : {}

  const [newCatalog, setNewCatalog] = useState<
    Record<string, ContractCatalog> | {}
  >(contractsRecord)

  function onCatalogAdd(value: any) {
    setNewCatalog({ ...newCatalog, [value.id]: value })
  }
  const form = useForm()

  useEffect(() => {
    if (!newCatalog) return
    const newValues = Object.entries(newCatalog).map(([_id, cat]) => cat)
    form.change('catalog', newValues)
  }, [form, newCatalog])

  const extraFilters = useMemo(() => {
    if (!id)
      return {
        contrat_id: 'null_',
      }
    return {
      customFilter: `contrat_id,null,or,contrat_id,eq,${id}`,
    }
  }, [id])

  return (
    <Box className={paper}>
      <Typography variant={'h6'}>{'Catalogue des prix'}</Typography>
      <ListView
        resource="FullCatalogOnlyPrice"
        basePath="/FullCatalogOnlyPrice"
        isEditable
        onCatalogAdd={onCatalogAdd}
        extraFilters={extraFilters}
        catalogId={id}
      />
    </Box>
  )
}
