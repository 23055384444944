import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Clear as ClearIcon } from '@material-ui/icons'
import type { Intervention } from '@willig/types/api'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { debounce } from 'lodash'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { Link, linkToRecord } from 'react-admin'

import type { BasicDoc, Hit, SearchBoxProvided } from 'react-instantsearch-core'
import { Index } from 'react-instantsearch-core'
import {
  InstantSearch,
  Hits,
  Highlight,
  connectSearchBox,
} from 'react-instantsearch-dom'
import { useEnv } from 'src/adapters/env'
import { SearchClient } from 'src/adapters/typesense'
import { useList } from 'src/libs/useGetList'

const useStyles = makeStyles((theme) => {
  return {
    '@global': {
      '.ais-Highlight-highlighted': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '.ais-Hits-list': {
        listStyle: 'none',

        padding: 0,
      },
      p: {
        margin: 0,
      },
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    cardContent: {
      flexGrow: 1,
    },
    hits: {
      position: 'absolute',
      right: '15px',
      top: '50px',
    },
  }
})

type PropsWithChildren<P> = P & { children?: ReactNode }
export const GlobalSearch = () => {
  const [focused, setFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const env = useEnv()
  const searchClient = useMemo(() => SearchClient({ env }), [env])
  return (
    <InstantSearch indexName="adresses" searchClient={searchClient}>
      <CustomSearchBox
        setInputValue={setInputValue}
        inputValue={inputValue}
        setFocused={setFocused}
      />
      {focused && (
        <Box
          position="absolute"
          top="70px"
          width="100%"
          display="flex"
          flexDirection="column"
          overflow="scroll"
          height="90vh"
          bgcolor="lightgrey"
          padding="20px 20px 0"
        >
          <Typography variant="h5">Adresses</Typography>
          <Hits
            hitComponent={(props) => (
              <HitComponent
                {...props}
                indexName="adresses"
                setFocused={setFocused}
              />
            )}
          />
          <Index indexName="contacts">
            <Typography variant="h5">Contact</Typography>
            <Hits
              hitComponent={(props) => (
                <HitComponent
                  {...props}
                  indexName="contacts"
                  setFocused={setFocused}
                />
              )}
            />
          </Index>
          <Index indexName="interventions">
            <Typography variant="h5">Intervention</Typography>
            <Hits
              hitComponent={(props) => (
                <HitComponent
                  {...props}
                  indexName="interventions"
                  setFocused={setFocused}
                />
              )}
            />
          </Index>
        </Box>
      )}
    </InstantSearch>
  )
}

type SearchBoxCustomProps = SearchBoxProvided & {
  setInputValue: Dispatch<SetStateAction<string>>
  inputValue: string
  setFocused: Dispatch<SetStateAction<boolean>>
}

const SearchBoxCustom = (props: SearchBoxCustomProps) => {
  const { refine, setInputValue, inputValue, setFocused } = props
  const refineDebounced = useMemo(() => debounce(refine, 50), [refine])

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target
      refineDebounced(value)

      setInputValue(value)
    },
    [refineDebounced, setInputValue],
  )
  const emptyInput = () => {
    setInputValue('')
  }

  return (
    <>
      <TextField
        value={inputValue}
        variant="filled"
        autoComplete="none"
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setTimeout(() => setFocused(false), 200)}
        InputProps={{
          endAdornment: inputValue && (
            <IconButton onClick={emptyInput} aria-label="delete">
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </>
  )
}

type IndexName = 'adresses' | 'contacts' | 'interventions'
const CustomSearchBox = connectSearchBox(SearchBoxCustom)
type HitComponentProps = PropsWithChildren<{
  hit: Hit<BasicDoc>
  indexName: IndexName
  setFocused: Dispatch<SetStateAction<boolean>>
}>
const HitComponent = (props: HitComponentProps) => {
  const { hit, indexName, setFocused } = props
  const classes = useStyles()
  const { card, cardContent } = classes

  const returnEndpoint = (index: IndexName) => {
    switch (index) {
      case 'adresses':
        return '/Adress'
      case 'contacts':
        return '/Contact'
      case 'interventions':
        return '/Intervention'
    }
  }

  const toggleFocused = () => setFocused(false)

  const onClick = useCallback(() => {}, [])
  const addressEdit = linkToRecord(returnEndpoint(indexName), hit.id, 'edit')
  const cardContentSwitch = () => {
    return (
      <Card variant="outlined" className={card} onClick={onClick}>
        <CardContent className={cardContent}>
          <HitBody indexName={indexName} hit={hit} />
        </CardContent>
        <CardActions>
          <Link onClick={toggleFocused} to={addressEdit}>
            <Button fullWidth color="primary" variant="contained" size="small">
              Voir
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
  return cardContentSwitch()
}

type HitBodyProps = {
  indexName: IndexName
  hit: Hit<BasicDoc>
}

const HitBody = (props: HitBodyProps) => {
  const { hit, indexName } = props

  const { data: InterventionData, isLoading } = useList<Intervention>(
    'Intervention',
    {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'rdv_date', order: 'DESC' },
      filter: { adresse_id: hit.id },
    },
  )

  const date =
    InterventionData?.[0]?.rdv_date !== undefined
      ? format(new Date(InterventionData?.[0]?.rdv_date), 'dd MMMM yyyy', {
          locale: fr,
        })
      : '-'

  switch (indexName) {
    case 'adresses':
      return (
        <Box
          display="grid"
          gridGap={4}
          gridTemplateColumns="2fr 1fr 1fr 2fr 1fr"
        >
          <Box>
            <Highlight tagName="mark" attribute="rue" hit={hit} />
          </Box>
          <p>{hit.code_postal}</p>
          <p>{hit.ville}</p>
          <p>{hit.contact}</p>
          <Box>
            <Highlight tagName="mark" attribute="note" hit={hit} />
          </Box>
          {isLoading ? <CircularProgress size={24} /> : date}
        </Box>
      )
    case 'contacts':
      return (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
          <Box>
            <Highlight tagName="mark" attribute="nom" hit={hit} />
          </Box>
          <Box>
            <Highlight tagName="mark" attribute="prenom" hit={hit} />
          </Box>

          <Box>
            {(hit?.phones as unknown as string[])?.map((phone: string) => (
              <p key={phone}>{phone}</p>
            ))}
          </Box>

          <Box>
            <Highlight tagName="mark" attribute="raison_sociale" hit={hit} />
          </Box>

          <p>{hit.type}</p>
          <p>{hit.adresse}</p>
        </Box>
      )
    case 'interventions':
      return (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 2fr 1fr">
          <Box>
            <Highlight tagName="mark" attribute="nom" hit={hit} />
          </Box>
          <Box>
            <Highlight tagName="mark" attribute="prenom" hit={hit} />
          </Box>
          <Box>
            <Highlight tagName="mark" attribute="rue" hit={hit} />
          </Box>
          <p>{`${hit.ville} ${hit.code_postal}`}</p>
          <p>{`${hit?.nom_facturation || ''} ${
            hit?.prenom_facturation || ''
          }`}</p>
          <p>{hit.rdv_date}</p>
        </Box>
      )
  }
}
