import type { Adress } from '@willig/types/api'
import type {
  Record,
  GetListParams,
  GetOneParams,
  GetOneResult,
} from 'react-admin'
import { useDataProvider } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import type { UseQueryOptions } from 'react-query'
import { useMutation, useQuery } from 'react-query'

import type { UseMutationOptions } from '../../../../node_modules/react-query/types/react/types'

export const DEFALUT_STALE_TIME = 1 * 60 * 1000

const defaultPagination = { page: 1, perPage: 100 }
const defaultSort = { field: 'id', order: 'DESC' }
const defaultFilter = {}

type ReactQueryListOptions<T extends Record = Record> = Omit<
  UseQueryOptions<T[], unknown, T[]>,
  'queryKey' | 'queryFn'
>

type ReactQueryGetOneOptions<T extends Record = Record> = Omit<
  UseQueryOptions<GetOneResult<T>, unknown, GetOneResult<T>>,
  'queryKey' | 'queryFn'
>

export function useList<T extends Record = Record>(
  resourceName: string,
  gelListOptions: Partial<GetListParams>,
  reactQueryOptions?: ReactQueryListOptions<T>,
) {
  const {
    pagination = defaultPagination,
    sort = defaultSort,
    filter = defaultFilter,
  } = gelListOptions
  const dataProvider = useDataProvider()
  const result = useQuery<T[], unknown, T[]>(
    ['getList', resourceName, gelListOptions],
    async () => {
      const dt = await dataProvider.getList<T>(resourceName, {
        pagination,
        sort,
        filter,
      })
      return dt.data
    },
    { staleTime: DEFALUT_STALE_TIME, ...reactQueryOptions },
  )
  return { ...result, data: result?.data ?? [] }
}
export function useOne<T extends Record = Record>(
  resourceName: string,
  options: GetOneParams,
  reactQueryOptions?: ReactQueryGetOneOptions<T>,
) {
  const dataProvider = useDataProvider()
  const result = useQuery<GetOneResult<T>, unknown, GetOneResult<T>>(
    ['getOne', resourceName, options],
    () => dataProvider.getOne<T>(resourceName, options),
    { staleTime: DEFALUT_STALE_TIME, ...reactQueryOptions },
  )
  return { ...result, data: result?.data?.data }
}

export function useUseQuery<T = unknown>(options: UseQueryOptions<T>) {
  return useQuery<T>({ staleTime: DEFALUT_STALE_TIME, ...options })
}

export function useUseMutation<T = unknown, U = unknown, V = void, W = unknown>(
  options: UseMutationOptions<T, U, V, W>,
) {
  return useMutation(options)
}

export function useFormContext<T>() {
  const form = useForm<T>()
  return {
    useWatch: () => useFormState<Adress>({ subscription: { values: true } }),
    setValue: (key: keyof T, value: T[keyof T]) => {
      form.change(key, value)
    },
  }
}
