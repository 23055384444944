import type { UseMutationOptions } from 'react-query'

import { v4 } from 'uuid'

import { useNxHttpClient } from '../adapters/nxHttpClient'
import type { ModalFormValues } from '../ressources/intervention/FreeSlots/types'

export type FreeSlotResponse = {
  id: string // For form only
  rdv_date: string // "2025-02-07",
  start_time: string // "08:30:00",
  end_time: string // "17:30:00",
  from_city: string // "Mulhouse",
  to_city: string // "Willig",
  distance: string // "103",
  sweep_id: string // "997b82bd-9ebf-11eb-a7b0-0242ac506802",
  login: string // "ange-patrick"
}

export function useInterventionQueryBuilder() {
  const nxHttpClient = useNxHttpClient()

  return {
    getFreeSlots(): UseMutationOptions<
      FreeSlotResponse[],
      unknown,
      ModalFormValues
    > {
      return {
        async mutationFn(payload: ModalFormValues) {
          const cleanPayload = clean(payload)
          const { data } = await nxHttpClient.patch<FreeSlotResponse[]>(
            `/Intervention/free_slots`,
            cleanPayload,
          )
          return data.map((dt) => {
            return { ...dt, id: v4() }
          })
        },
      }
    },
  }
}

function clean(payload: ModalFormValues) {
  const { sweep_id } = payload

  if (sweep_id === 0) {
    return {
      ...payload,
      sweep_id: undefined,
    }
  }
  return payload
}
