import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import type { FullContactAdresse } from '@willig/types/api'
import { SimpleShowLayout, TextField } from 'react-admin'
import { Boundary } from 'src/components/Boundary'
import { useList } from 'src/libs/useGetList'

const useStyles = makeStyles({
  suggestedCard: {
    minWidth: 275,
    display: 'flex',
    alignItems: 'flex-end',
  },
  suggestedInfo: {
    flexGrow: 1,
  },
})

type sugesstedAdressesProps = {
  facture_contact_id: string
  cb: (choice: string | null) => void
}
export const SuggestedAdresses = (props: sugesstedAdressesProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Boundary>
        <SuggestedAdressesImpl {...props} />
      </Boundary>
    </div>
  )
}

function SuggestedAdressesImpl(props: sugesstedAdressesProps) {
  const { facture_contact_id, cb } = props

  const filter = { contact_id: [facture_contact_id], '!deleted': 1 }

  const { data } = useList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: filter,
    },
    { enabled: Boolean(facture_contact_id), suspense: true },
  )

  const classes = useStyles()
  return (
    <>
      {data?.map((res: any) => (
        <Card key={res.id} className={classes.suggestedCard} variant="outlined">
          <CardContent className={classes.suggestedInfo}>
            <SimpleShowLayout record={res}>
              <TextField source="rue" />
              <TextField source="code_postal" />
              <TextField source="ville" />
              <TextField source="cote" label="Intitulé d'adresse" />
              <TextField source="etage" />
              <TextField source="commentaire" />
            </SimpleShowLayout>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => cb(res.adresse_id)}>
              Choisir
            </Button>
          </CardActions>
        </Card>
      ))}
    </>
  )
}
