import { Box, Typography } from '@material-ui/core'
import { DeleteForever as DeleteForeverIcon } from '@material-ui/icons'
import type { FullContratAddress } from '@willig/types/api'
import type { EditProps } from 'react-admin'

import {
  Edit,
  FormWithRedirect,
  SaveButton,
  useDelete,
  Button,
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin'

import { useFormState } from 'react-final-form'

import { useInvalidate } from 'src/hooks/useInvalidate'

import { DefaultPagination } from '../../components/defaultPagination'

import { CatalogListContent } from './components/catalogList'
import { CreateEditForm } from './CreateEditForm'
import { useContractStyle } from './hooks/useContractStyle'

export const EditView = (props: EditProps) => {
  return (
    <Edit {...props} mutationMode="optimistic">
      <FormWithRedirect
        redirect="edit"
        render={(renderProps) => {
          const { saving, handleSubmitWithRedirect } = renderProps
          return (
            <Box display={'flex'} flexDirection={'column'} gridGap={16}>
              <CreateEditForm />
              <SaveButton
                saving={saving}
                label={'Enregistrer'}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
              />
              <RelatedContratAddresses />
              <SaveButton
                saving={saving}
                label={'Enregistrer'}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
              />
              <CatalogListContent />
              <SaveButton
                saving={saving}
                label={'Enregistrer'}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
              />
            </Box>
          )
        }}
      />
    </Edit>
  )
}

function RelatedContratAddresses() {
  const { values } = useFormState<any>({
    subscription: { values: true },
  })

  const { paper } = useContractStyle()

  const { id: contractId } = values

  return (
    <Box className={paper}>
      <Typography variant={'h6'}>{'Adresses liées au contrat'}</Typography>

      <List
        filter={{ contrat_id: [contractId], deleted: [false] }}
        hasList={true}
        resource="FullContratAddress"
        basePath="/"
        pagination={<DefaultPagination />}
        perPage={25}
        bulkActionButtons={false}
        actions={false}
        empty={false}
      >
        <ListContent />
      </List>
    </Box>
  )
}

function ListContent() {
  const { deleteButton } = useContractStyle()

  const [deleteLink] = useDelete()

  const invalidate = useInvalidate()

  function deleteContractRelation(record: FullContratAddress) {
    if (
      window.confirm(
        `L'adresse ${record.rue} ne sera plus liée à ce contrat, continuer ? `,
      )
    ) {
      deleteLink('ContratAddress', record.id, record, {
        onSuccess() {
          invalidate('FullContratAddress')
        },
      })
    }
  }

  return (
    <Datagrid
      basePath="/"
      rowClick={(_id, _base, record) => {
        return `/Adress/${(record as FullContratAddress).address_id}`
      }}
    >
      <TextField source="rue" />
      <TextField source="code_postal" />
      <TextField source="ville" />
      <TextField source="cote" label="Intitulé d'adresse" />
      <FunctionField<FullContratAddress>
        reference="address_id"
        label=""
        render={(record) => {
          return (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={(event) => {
                  deleteContractRelation(record!)
                  event.stopPropagation()
                }}
                className={deleteButton}
              >
                <>
                  <DeleteForeverIcon /> Délier
                </>
              </Button>
            </Box>
          )
        }}
      />
    </Datagrid>
  )
}
