import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import type { User } from '@willig/types/api'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import type { Control } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { useList } from 'src/libs/useGetList'

import type { ModalFormValues } from './types'

const durationsOptions = [
  { duration: 30, name: '30 minutes' },
  { duration: 45, name: '45 minutes' },
  { duration: 60, name: '1 heure' },
  { duration: 90, name: '1 heure et demi' },
  { duration: 120, name: '2 heure' },
  { duration: 180, name: '3 heure' },
  { duration: 240, name: '4 heure' },
  { duration: 300, name: '5 heure' },
  { duration: 360, name: '6 heure' },
]

export function FormContent(props: { control: Control<ModalFormValues> }) {
  const { control } = props

  const { setValue } = useFormContext()

  const { data: sweeps } = useList<User>('User', {
    filter: { '<access_level': [50], '>access_level': [9] },
    sort: { field: 'login', order: 'ASC' },
  })

  const options = useMemo(() => {
    return sweeps?.map((sweep) => {
      return {
        name: sweep.login,
        id: sweep.id,
      }
    })
  }, [sweeps])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        marginRight: 'auto',
        gridGap: 20,
      }}
    >
      <SelectWrapper label="Durée du rdv">
        <Controller
          name="duration"
          control={control}
          rules={{ required: true }}
          render={(renderProps) => {
            const { field } = renderProps
            return (
              <FormControl fullWidth>
                <InputLabel>{'Durée'}</InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  style={{ height: '46px' }}
                  {...field}
                >
                  {durationsOptions?.map((opt) => {
                    return (
                      <MenuItem key={opt.name} value={opt.duration}>
                        {opt.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )
          }}
        />
      </SelectWrapper>

      <SelectWrapper label="ramoneur">
        <Controller
          name="sweep_id"
          control={control}
          render={(renderProps) => {
            const { field } = renderProps
            const { value } = field

            const isChecked = Boolean(!value)

            return (
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      // value={value}
                      onChange={() => {
                        if (!isChecked) {
                          setValue('sweep_id', 0)
                        }
                      }}
                    />
                  }
                  label={'Peu importe'}
                />
              </FormControl>
            )
          }}
        />

        <Controller
          name="sweep_id"
          control={control}
          render={(renderProps) => {
            const { field } = renderProps
            return (
              <FormControl fullWidth>
                <InputLabel>{'Ramoneur'}</InputLabel>
                <Select
                  fullWidth
                  displayEmpty
                  variant="filled"
                  style={{ height: '46px' }}
                  {...field}
                >
                  {options?.map((opt) => {
                    return (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )
          }}
        />
      </SelectWrapper>
    </Box>
  )
}

type SelectWrapperProps = {
  label: string
}

function SelectWrapper(props: PropsWithChildren<SelectWrapperProps>) {
  const { label, children } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: 4 }}>
      <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
      {children}
    </Box>
  )
}
