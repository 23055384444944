import { isEmpty } from 'lodash'

import React from 'react'
import type { CreateProps } from 'react-admin'
import {
  Create,
  FormWithRedirect,
  useCreate,
  useNotify,
  useUpdateMany,
  useRedirect,
} from 'react-admin'
import type { InterventionCreate } from 'src/types/api/extendedTypes'

import { InterventionFields } from './interventionForm'

export const RessourceCreate = (props: CreateProps) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const [createIntervention] = useCreate('Intervention')
  const [updateMany] = useUpdateMany()

  const handleSubmitCreate = async (values: unknown) => {
    const { otherEntries, ...interventionData } = values as InterventionCreate
    const ContactAdresseToDelete = Object.entries(otherEntries ?? {})
      .filter(([, value]) => value === 2)
      .map((el) => el[0])

    createIntervention(
      { payload: { data: interventionData } },
      {
        onSuccess: () => {
          if (isEmpty(ContactAdresseToDelete)) {
            notify("Création de l'intervention", 'success')
            redirect('list', '/Intervention')
          }
          updateMany(
            'ContactsAdress',
            ContactAdresseToDelete,
            { deleted: 1 },
            {
              onSuccess: () => {
                redirect('list', '/Intervention')
              },
              onFailure: () => {
                notify('Une erreur est survenue', 'error')
              },
            },
          )
        },
        onFailure: (errorValue: { error: any }) => {
          notify(errorValue.error.message, 'error')
        },
      },
    )
  }

  return (
    <Create {...props}>
      <FormWithRedirect
        save={handleSubmitCreate}
        render={(renderProps) => {
          const { saving, handleSubmitWithRedirect } = renderProps
          return (
            <>
              <InterventionFields
                saving={saving}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
              />
            </>
          )
        }}
      />
    </Create>
  )
}
