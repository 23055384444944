import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1, 0, 1),
    },
    cell: {
      padding: theme.spacing(1, 0, 1),
    },
    paper: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 2),
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
    customRow: {
      color: 'white', // theme.palette.primary.main,
      background: theme.palette.primary.light,
      fontWeight: 'bolder',
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  }),
)

export function useContractStyle() {
  return useStyles()
}
