import { Chip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import {
  Add as IconContentAdd,
  Cancel as IconCancel,
  Edit as IconContentEdit,
} from '@material-ui/icons'

import type { Contact, ContactsAdress } from '@willig/types/api'
import { EnumContactType } from '@willig/types/api'
import { useState, useCallback, useEffect } from 'react'
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  TextInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useUpdate,
  RadioButtonGroupInput,
  useUpdateMany,
} from 'react-admin'

import { useForm, useFormState } from 'react-final-form'
import { useSibHistory } from 'src/hooks/useSib'
import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'
import { useList } from 'src/libs/useGetList'

import {
  validateJuridical,
  validatePhysical,
} from 'src/ressources/contact/Create'

import { TypesenseInput } from '../../../components/Typesense/TypesenseInput'
import { features } from '../../../config'
import { useIsJuridical, useIsPhysical } from '../checkContactType'

import ContactQuickPreviewButton from './ContactQuickPreviewButton'
import { MessagesHistoryModal } from './MessagesHistoryModal'

const spySubscription = { values: true }

type ContacteReferenceInputProps = {
  filterToQuery: (searchText: string) => { nom: string }
  onChange?: () => void
  target: 'contact_id' | 'facture_contact_id'
  inputLabel: string
  source: string
  reference: string
  validate: (value: any, values: any) => string | { message: string; args: any }
  perPage: number
  emptyContactInput: string[]
  isDisabled?: boolean
}

const ContactReferenceInput = (props: ContacteReferenceInputProps) => {
  const { target, inputLabel, emptyContactInput, isDisabled = false } = props
  const classes = useStyles()
  const [version, setVersion] = useState(0)
  const { values } = useFormState({ subscription: spySubscription })
  const handleChange = useCallback(() => setVersion(version + 1), [version])
  const [undeleteContact, setUndeleteContact] = useState('')
  const form = useForm()
  const notify = useNotify()
  const [modalState, toggleModal] = useState<boolean>(false)

  const { data } = useGetOne<Contact>('Contact', values[target], {
    enabled: Boolean(values[target]),
  })

  const [update] = useUpdate('Contact')

  const { data: addressRelations, refetch } = useList<ContactsAdress>(
    'ContactsAdress',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'DESC' },
      filter: {
        contact_id: undeleteContact,
        deleted: 1,
      },
    },
    { enabled: undeleteContact !== '', suspense: true },
  )

  const [updateMany] = useUpdateMany()

  const messageHistory = useSibHistory({ contactId: data?.id })
  useEffect(() => {
    if (data !== undefined && data.deleted) {
      setUndeleteContact(data.id)
      form.change('contact_id', null)
      notify(
        'Ce contact a été effacé, veuillez sélectionner un autre contact',
        'error',
        {},
        undefined,
        3000,
      )
    }
  }, [data, form, notify])

  function handleDeletedContact() {
    const relationsToRehab = addressRelations?.map((elt) => elt.id)
    updateMany(
      'ContactsAdress',
      relationsToRehab,
      { deleted: false },
      {
        onSuccess: () => {
          toggleModal(false)
          refetch()
        },
        onFailure: (response: { error: any }) => {
          notify(response.error.message, 'error')
          refetch()
        },
      },
    )
    update(
      { payload: { data: { deleted: false }, id: undeleteContact } },
      {
        onSuccess() {
          window.location.reload()
        },
        onFailure() {
          notify('Une erreur est survenue', 'error')
          refetch()
        },
      },
    )
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <p>{inputLabel}</p>
          {!isDisabled && (
            <ContactButton onChange={handleChange} target={target} />
          )}
        </div>
        <TypesenseInput
          data={data}
          source={target}
          isDisabled={isDisabled}
          indexName="contacts"
          inputLabel="Rechercher un contact"
          emptyInputArray={emptyContactInput}
        />
        {undeleteContact !== undefined &&
          undeleteContact.length > 1 &&
          inputLabel === 'Contact' && (
            <div className={classes.wrapperChip}>
              <Chip
                classes={{ root: classes.contactChip }}
                label="Contact effacé, veuillez changer de contact"
              />
              {
                <Button
                  label="Rétablir"
                  color="primary"
                  variant="contained"
                  onClick={handleDeletedContact}
                ></Button>
              }
            </div>
          )}

        {data && (
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.cardContent}>
              <SimpleShowLayout record={data}>
                <TextField fullWidth source="raison_sociale" />
                <TextField fullWidth source="nom" />
                <TextField fullWidth source="prenom" />
                <TextField fullWidth source="mobile" />
                <TextField fullWidth source="fixe" />
                <TextField fullWidth source="email" />
                <TextField fullWidth source="commentaire" />
              </SimpleShowLayout>

              <div className={classes.cardFooter}>
                {!!data && (
                  <ContactButton
                    isEdit
                    target={target}
                    onChange={handleChange}
                    data={data}
                  />
                )}
                {!!data && <ContactQuickPreviewButton data={data} />}
              </div>
              {!!data && (
                <>
                  <MuiButton
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => toggleModal(true)}
                  >
                    {"Ouvrir modal de l'historique des échanges"}
                  </MuiButton>
                  <MessagesHistoryModal
                    historicData={messageHistory}
                    toggleModal={toggleModal}
                    modalState={modalState}
                  />
                </>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </>
  )
}

export default ContactReferenceInput

function ContactButton(props: {
  onChange: any
  target: 'contact_id' | 'facture_contact_id'
  data?: any
  isEdit?: boolean
}) {
  const { onChange, target, data, isEdit = false } = props
  const [showDialog, setShowDialog] = useState(false)
  const [createContact, { loading: loadingCreate }] = useCreate('Contact')
  const [update, { loading: loadingUpdate }] = useUpdate('Contact', data?.id)
  const notify = useNotify()
  const form = useForm()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmitCreate = async (values: any) => {
    createContact(
      { payload: { data: values } },
      {
        onSuccess: async (response: { data: any }) => {
          notify('Création du contact effectuée', 'success')
          setShowDialog(false)
          form.change(target, response.data.id)
          onChange()
        },
        onFailure: (errorValue: { error: any }) => {
          notify(errorValue.error.message, 'error')
        },
      },
    )
  }

  const handleSubmitUpdate = async (values: any) => {
    update(
      { payload: { data: values, id: data.id } },
      {
        onSuccess: (response) => {
          notify('Mise à jour du contact effectuée', 'success')
          setShowDialog(false)
          form.change(target, response.data.id)
          onChange()
        },
        onFailure: (errorValue) => {
          notify(errorValue.error.message, 'error')
        },
      },
    )
  }

  const newContact = useForm<Contact>()
  return (
    <>
      {isEdit ? (
        <Button onClick={handleClick} label="Modifier">
          <IconContentEdit />
        </Button>
      ) : (
        <Button onClick={handleClick} label="Nouveau">
          <IconContentAdd />
        </Button>
      )}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={`${isEdit ? 'Modifier' : 'Créer'} un contact`}
      >
        <DialogTitle>{`${
          isEdit ? 'Modifier' : 'Créer'
        } un contact`}</DialogTitle>

        <FormWithRedirect
          initialValues={isEdit && data}
          record={data ?? newContact}
          resource="Adress"
          validate={validateForm}
          save={isEdit ? handleSubmitUpdate : handleSubmitCreate}
          render={(renderProps) => {
            const { handleSubmitWithRedirect, saving } = renderProps
            return (
              <>
                <ModalFormContent isEdit={isEdit} />
                <DialogActions>
                  <Button
                    label="Annuler"
                    onClick={handleCloseClick}
                    disabled={isEdit ? loadingUpdate : loadingCreate}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    label={`${isEdit ? 'Modifier' : 'Créer'} le contact`}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    saving={saving}
                    disabled={isEdit ? loadingUpdate : loadingCreate}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}

type ModalFormContentProps = {
  isEdit?: boolean
}

function ModalFormContent(props: ModalFormContentProps) {
  const { isEdit = false } = props
  const { values } = useFormState<Contact>({ subscription: { values: true } })

  const classes = useStyles()

  const isPhysical = useIsPhysical(values)

  const isJuridical = useIsJuridical(values)
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        <fieldset>
          <legend>Identité</legend>
          <Box>
            <SelectInput
              fullWidth
              source="type"
              required={true}
              choices={convertEnumAsOptionWithId(EnumContactType)}
              disabled={isEdit && features.disableContactType}
            />
          </Box>
          <Box>
            <TextInput
              fullWidth
              source="raison_sociale"
              required={isJuridical}
            />
          </Box>
          <Box display="flex" className={classes.inputGap}>
            <TextInput fullWidth source="nom" required={isPhysical} />
            <TextInput fullWidth source="prenom" required={isPhysical} />
          </Box>
        </fieldset>
        <fieldset>
          <legend>Coordonées</legend>
          <Box display="flex" className={classes.inputGap}>
            <TextInput fullWidth source="fixe" />
            <TextInput fullWidth source="mobile" />
          </Box>
          <Box>
            <TextInput fullWidth source="email" />
          </Box>
        </fieldset>
        <fieldset>
          <legend>Complémentaire</legend>
          <Box>
            <TextInput multiline fullWidth source="commentaire" />
          </Box>
          <Box>
            <RadioButtonGroupInput
              source="accept_sms"
              required={isPhysical}
              choices={[
                { id: true, name: 'Oui' },
                { id: false, name: 'Non' },
              ]}
            />
          </Box>
          <Box>
            <RadioButtonGroupInput
              source="accept_email"
              required={isPhysical}
              choices={[
                { id: true, name: 'Oui' },
                { id: false, name: 'Non' },
              ]}
            />
          </Box>
        </fieldset>
      </DialogContent>
    </>
  )
}

function validateForm(values: Contact) {
  const { type } = values
  let errors: Partial<Record<keyof Contact, string | undefined>> = {}
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }

  if (
    type === EnumContactType.particulier ||
    type === EnumContactType.contact
  ) {
    errors = { ...errors, ...validatePhysical(values) }
  }
  if (
    type === EnumContactType.professionnel ||
    type === EnumContactType.collectivité ||
    type === EnumContactType.syndic ||
    type === EnumContactType.gestionnaire
  ) {
    errors = { ...errors, ...validateJuridical(values) }
  }

  return errors
}

const useStyles = makeStyles((theme) => {
  return {
    card: {
      flexGrow: 1,
    },
    cardContent: {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
    },
    dialogContent: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    inputGap: {
      gap: theme.spacing(2),
    },
    autoComplete: {
      width: '100%',
    },
    wrapperChip: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginTop: theme.spacing(2),
    },
    contactChip: {
      width: theme.spacing(40),
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      marginTop: theme.spacing(1),
    },
    wrapper: { display: 'flex', flexDirection: 'column' },
    header: { display: 'flex', justifyContent: 'space-between' },
  }
})
