import type { Theme } from '@material-ui/core'
import {
  makeStyles,
  Button,
  Typography,
  Modal,
  Card,
  CardContent,
  Box,
  useTheme,
} from '@material-ui/core'
import type { File, Intervention } from '@willig/types/api'
import { useState } from 'react'
import { useGetOne, Link } from 'react-admin'

import { parseInterventionDetail } from 'src/adapters/antiCorruptionLayerProxy'
import { useEnv } from 'src/adapters/env'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'
import { handleInvoicePrices } from 'src/libs/handleInvoicePrices'
import { useInvoiceQueryBuilder } from 'src/libs/invoiceQueryBuilder'
import { money } from 'src/libs/money'
import { SafeHtml } from 'src/libs/SafeHtml'
import {
  getSinaoBackgroundColor,
  getSinaoStatusLabel,
} from 'src/page/Invoice/Components'
import { useInterventionStatus } from 'src/ressources/statusInterventions/useInterventionStatus'
import { useSweeper } from 'src/ressources/user/useSweeper'
import type { InterventionDetail } from 'src/types/api/extendedTypes'
import { InterventionStatusEnum } from 'src/types/api/extendedTypes'

import { useUseMutation, useUseQuery } from '../libs/useGetList'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: 16,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 4,
      color: theme.palette.common.white,
      maxWidth: 'fit-content',
      fontWeight: 'bold',
    },
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
    detailsHtml: {
      '& small': {
        color: theme.palette.text.secondary,
      },
    },
  }
})

type Props = {
  intervention: Intervention
}

export function InterventionCard(props: Props) {
  const { intervention } = props
  const { rdv_date, rdv_time, id, status, sweep_id } = intervention

  const styles = useStyles()

  const httpClient = useNxHttpClient()
  const { data: details } = useUseQuery<InterventionDetail>({
    queryKey: ['Intervention', id, 'reportDetail'],
    queryFn: async () => {
      const { data } = await httpClient.patch('/Intervention/reportDetail', {
        interventionId: id,
      })

      return parseInterventionDetail(data)
    },

    suspense: true,
    enabled: status === InterventionStatusEnum.done,
  })

  const { label } = useInterventionStatus(status, { suspense: true })!
  const sweeper = useSweeper(sweep_id, { suspense: true })!
  const prix_total = handleInvoicePrices(details)
  const formattedDate = new Date(rdv_date).toLocaleDateString('fr', {
    dateStyle: 'long',
  })

  return (
    <div>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <strong>{label}, </strong>
        <Link to={`/Intervention/${id}`} color="">
          {formattedDate} {rdv_time}
        </Link>
        <em>par {sweeper.login}</em>
        {prix_total && <strong> ({money(prix_total, 'TTC')})</strong>}
        <Box sx={{ marginLeft: 'auto' }}>
          <SinaoInvoiceButton detail={details} />
          {details && <DocumentModalButton interventionDetails={details} />}
        </Box>
      </Typography>
      {details?.installations?.map((installation, index) => {
        const { detail, prestations } = installation
        return (
          <Card key={index} elevation={2}>
            <CardContent
              style={{
                display: 'grid',
                gap: 8,
                gridTemplateColumns: '1fr 1fr',
                padding: 8,
              }}
            >
              <SafeHtml className={styles.detailsHtml}>{detail}</SafeHtml>
              <SafeHtml className={styles.detailsHtml}>{prestations}</SafeHtml>
            </CardContent>
          </Card>
        )
      })}
      {!details?.installations?.length && intervention.infos && (
        <Card elevation={2}>
          <CardContent
            style={{
              display: 'grid',
              gap: 8,
              gridTemplateColumns: '1fr 1fr',
              padding: 8,
            }}
          >
            {intervention.infos}
          </CardContent>
        </Card>
      )}
    </div>
  )
}

function SinaoInvoiceButton(props: { detail?: InterventionDetail }) {
  const { detail } = props
  const theme = useTheme()
  const invoiceQueryBuilder = useInvoiceQueryBuilder()

  const { mutateAsync } = useUseMutation(invoiceQueryBuilder.getInvoicePdf())
  const { root } = useStyles()
  if (!detail) return <></>
  const {
    sinao_id,
    sinao_invoice_reference,
    sinao_invoice_status,
    intervention_report_id,
  } = detail
  const backgroundColor = getSinaoBackgroundColor(theme, sinao_invoice_status)

  if (!sinao_id) return <></>
  const label =
    sinao_invoice_reference ??
    getSinaoStatusLabel(sinao_id, sinao_invoice_status)

  if (!label) return <></>

  return (
    <>
      <Box
        className={root}
        style={{
          backgroundColor: backgroundColor,
          cursor: intervention_report_id ? 'pointer' : 'default',
        }}
        onClick={async () => {
          if (intervention_report_id) {
            const fileURL = await mutateAsync(intervention_report_id)
            window.open(fileURL, '_blank')
          }
        }}
      >
        {label}
      </Box>
    </>
  )
}

const modaleStyles = {
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
}

type DocumentModalProps = {
  interventionDetails: InterventionDetail
}

function DocumentModalButton(props: DocumentModalProps) {
  const { interventionDetails } = props
  const classes = useStyles()
  const [isOpen, setOpen] = useState<boolean>(false)

  const devis = interventionDetails.devis?.pdfUUID
  const devis_complementaire = interventionDetails.devis_complementaire?.pdfUUID
  const cheque = interventionDetails.payment?.fileUUID
  const quittance = interventionDetails.invoice?.pdfUUID
  const { bon_intervention, certificat } = interventionDetails
  const etat_des_lieux = interventionDetails?.etat_des_lieux?.pdfUUID

  const isEmpty =
    !devis &&
    !devis_complementaire &&
    !cheque &&
    !quittance &&
    !certificat &&
    !bon_intervention &&
    !etat_des_lieux

  if (isEmpty) return null

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={() => setOpen(true)}
      >
        Voir documents
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <div style={modaleStyles} className={classes.paper}>
          <h2>Liste de document</h2>
          {devis && <DocumentList type="Devis" documentIds={devis} />}
          {devis_complementaire && (
            <DocumentList
              type="Devis Complementaire"
              documentIds={devis_complementaire}
            />
          )}
          {cheque && <DocumentList type="Cheque" documentIds={cheque} />}
          {quittance && (
            <DocumentList
              type="Quittance de paiement"
              documentIds={quittance}
            />
          )}
          {certificat && (
            <DocumentList type="Certificat" documentIds={[certificat]} />
          )}
          {bon_intervention && (
            <DocumentList
              type="Bon d'intervention"
              documentIds={[bon_intervention]}
            />
          )}
          {etat_des_lieux && (
            <DocumentList type="Etat des lieux" documentIds={etat_des_lieux} />
          )}
        </div>
      </Modal>
    </>
  )
}

type DownloadDocumentProps = {
  file_id: string
}
function DownloadDocument(props: DownloadDocumentProps) {
  const { file_id } = props
  const { data } = useGetOne<File>('File', file_id)
  const env = useEnv()
  return (
    <Button
      color="primary"
      variant="contained"
      target="_blank"
      href={`${env.API_URL}/${data?.path}`}
      download
      rel="noreferrer"
    >
      voir le document
    </Button>
  )
}

type DocumentListProps = {
  type: string
  documentIds: string[]
}

function DocumentList(props: DocumentListProps) {
  const { type, documentIds } = props

  return (
    <>
      <h3>{type}</h3>
      {documentIds.map((documentId) => {
        return <DownloadDocument file_id={documentId} key={documentId} />
      })}
    </>
  )
}
