import { Button } from '@material-ui/core'
import type { User } from '@willig/types/api'
import { differenceInCalendarDays, add } from 'date-fns'
import { useFormContext, useWatch } from 'react-hook-form'
import { DatePicker, Picker } from 'src/components/Form'
import { Stack } from 'src/components/UI/Stack'

import { useList } from 'src/libs/useGetList'

import { SinaoStatusEnum } from '../../../types/api/extendedTypes'
import { ParticularFilters, ProFilters } from '../Components'

type Props = {
  pro: boolean
  onChange: () => (e?: React.BaseSyntheticEvent) => Promise<void>
}

export function InvoiceFilters(props: Props) {
  const { pro, onChange } = props

  return (
    <Stack
      css={{
        display: 'flex',
        gap: 8,
      }}
    >
      {pro ? (
        <ProFilters onChange={onChange} />
      ) : (
        <ParticularFilters onChange={onChange} />
      )}
    </Stack>
  )
}

export function PresetsFilters(props: Pick<Props, 'onChange'>) {
  const { onChange } = props
  const { setValue } = useFormContext()

  const today = new Date()
  const yesterDay = add(today, { days: -1 })

  return (
    <Stack
      css={{
        display: 'flex',
        width: '100%',
        gap: 8,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setValue('startDate', yesterDay)
          setValue('endDate', yesterDay)
          onChange()()
        }}
      >
        Hier
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setValue('startDate', today)
          setValue('endDate', today)

          onChange()()
        }}
      >
        Aujourd'hui
      </Button>
    </Stack>
  )
}

export function PeriodFilter(props: Pick<Props, 'onChange'>) {
  const { onChange } = props
  const { setValue } = useFormContext()

  const startDate = useWatch({ name: 'startDate' })
  const endDate = useWatch({ name: 'endDate' })
  return (
    <>
      <DatePicker
        name="startDate"
        label="Début de période"
        onChange={(date) => {
          const diff = endDate
            ? differenceInCalendarDays(new Date(endDate), date)
            : undefined
          if (diff && diff <= 0) {
            setValue('endDate', date)
          }
          onChange()()
        }}
      />
      <DatePicker
        name="endDate"
        label="Fin de période"
        onChange={(date) => {
          const diff = startDate
            ? differenceInCalendarDays(date, new Date(startDate))
            : undefined
          if (diff && diff <= 0) {
            setValue('startDate', date)
          }
          onChange()()
        }}
      />
    </>
  )
}

export function PaymentFilter(props: Pick<Props, 'onChange'>) {
  const { onChange } = props

  return (
    <Picker
      options={[
        {
          value: 'especes',
          label: 'Espèce',
        },
        {
          value: 'cheque',
          label: 'Chèque',
        },
        {
          value: 'CB',
          label: 'Carte bancaire',
        },
        {
          value: 'impayé',
          label: 'Sans paiement',
        },
        {
          value: 'payé',
          label: 'Avec paiement',
        },
      ]}
      name="type_paiement"
      label="Paiement"
      onChange={() => onChange()()}
    />
  )
}

export function SinaoStatusFiter(props: Pick<Props, 'onChange'>) {
  const { onChange } = props

  return (
    <Picker
      options={[
        { value: SinaoStatusEnum.notBilled, label: 'Non facturé' },
        { value: SinaoStatusEnum.draft, label: 'Brouillon' },
        { value: SinaoStatusEnum.paid, label: 'Payée' },
        { value: SinaoStatusEnum.final, label: 'Facturée' },
        { value: SinaoStatusEnum.billed, label: 'Envoyé en facturation' },
      ]}
      name="sinaoStatus"
      label="Status Sinao"
      onChange={() => onChange()()}
    />
  )
}

export function SweepFilter(props: Pick<Props, 'onChange'>) {
  const { onChange } = props
  const { data: sweeps } = useList<User>('User', {
    filter: { '<access_level': [50], '>access_level': [9] },
    sort: { field: 'login', order: 'ASC' },
  })
  return (
    <Picker
      options={
        sweeps.map((sweep) => {
          return {
            value: sweep.login,
            label: sweep.login,
          }
        }) ?? []
      }
      name="ramoneur"
      label="Ramoneur"
      onChange={() => onChange()()}
    />
  )
}
