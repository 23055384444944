import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import type { Contact } from '@willig/types/api'
import { EnumContactType } from '@willig/types/api'
import { RadioButtonGroupInput, SelectInput, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'

import { features } from '../../config'
import { useIsJuridical, useIsPhysical } from '../intervention/checkContactType'

type Props = {
  edit?: boolean
}

export const CreateEditInputs = (props: Props) => {
  const { edit = false } = props
  function checkInputs(value: string) {
    const val = value.trim()
    if (val.length === 0) {
      return null
    }
    return val
  }

  const { values } = useFormState<Contact>({ subscription: { values: true } })

  const isPhysical = useIsPhysical(values)
  const isJuridical = useIsJuridical(values)

  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <SelectInput
              fullWidth
              source="type"
              required={true}
              choices={convertEnumAsOptionWithId(EnumContactType)}
              disabled={edit && features.disableContactType}
            />
            <TextInput
              fullWidth
              source="raison_sociale"
              required={isJuridical}
            />
          </Box>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="nom" required={isPhysical} />
            <TextInput fullWidth source="prenom" required={isPhysical} />
          </Box>
          <Box display="flex" gridGap={16}>
            <TextInput
              fullWidth
              source="mobile"
              parse={(val: string) => checkInputs(val)}
            />
            <TextInput
              fullWidth
              source="fixe"
              parse={(val: string) => checkInputs(val)}
            />
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box>
            <TextInput
              fullWidth
              source="email"
              parse={(val: string) => checkInputs(val)}
            />
          </Box>

          <Box>
            <TextInput fullWidth multiline source="commentaire" />
          </Box>
          <Box>
            <RadioButtonGroupInput
              source="accept_sms"
              required={isPhysical}
              choices={[
                { id: true, name: 'Oui' },
                { id: false, name: 'Non' },
              ]}
            />
          </Box>
          <Box>
            <RadioButtonGroupInput
              source="accept_email"
              required={isPhysical}
              choices={[
                { id: true, name: 'Oui' },
                { id: false, name: 'Non' },
              ]}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
