import { isValidDate } from '@fullcalendar/react'
import { useNotify } from 'react-admin'
import type { UseMutationOptions, UseQueryOptions } from 'react-query'
import { useQueryClient } from 'react-query'
import type { ApiFilters } from 'src/page/Invoice/types'
import type { DailyInvoices } from 'src/types/api/extendedTypes'

import { useNxHttpClient } from '../adapters/nxHttpClient'

import { DEFALUT_STALE_TIME } from './useGetList'

export function useInvoiceQueryBuilder() {
  const nxHttpClient = useNxHttpClient()
  const queryClient = useQueryClient()
  const notify = useNotify()

  return {
    getDailyInvoices(apiFilters: ApiFilters): UseQueryOptions<DailyInvoices[]> {
      return {
        queryKey: ['dailyInvoices', apiFilters],
        async queryFn() {
          const data = await nxHttpClient.patch<DailyInvoices[]>(
            '/InterventionReport/getDailyInvoices',
            { ...apiFilters },
          )
          return data.data
        },
        enabled: isValidRange(apiFilters.startDate, apiFilters.endDate),
        staleTime: DEFALUT_STALE_TIME,
        suspense: true,
      }
    },
    revertInvoice(): UseMutationOptions<unknown, unknown, string> {
      return {
        mutationFn(sinaoId) {
          return nxHttpClient.patch(`/FullInvoicesIntervention/cancelInvoice`, {
            sinao_id: sinaoId,
          })
        },
        async onSuccess() {
          await queryClient.invalidateQueries()
          notify(`facture annulée`, 'success', undefined, false, 1500)
        },
        onError(error) {
          console.error(error)
          notify(`erreur`, 'error', undefined, false, 1500)
        },
      }
    },
    sendInvoices(): UseMutationOptions<unknown, unknown, string[]> {
      return {
        mutationFn(invoiceIds) {
          return Promise.all(
            invoiceIds.map((invoiceId) => {
              return nxHttpClient.patch(
                `/FullInvoicesIntervention/createInvoice`,
                {
                  id: invoiceId,
                },
              )
            }),
          )
        },
        async onSuccess() {
          await queryClient.invalidateQueries()
          notify(`factures envoyées`, 'success', undefined, false, 1500)
        },
        onError(error) {
          console.error(error)
          notify(`erreur`, 'error', undefined, false, 1500)
        },
      }
    },
    sendProInvoices(): UseMutationOptions<
      unknown,
      unknown,
      {
        invoiceIds: string[]
        contactIds: string[]
        addressIds: string[]
        proIds: string[]
      }
    > {
      return {
        mutationFn({ invoiceIds, contactIds, addressIds, proIds }) {
          const payload = encodeProPayload(
            invoiceIds,
            contactIds as string[],
            addressIds as string[],
            proIds as string[],
          )
          return nxHttpClient.patch(
            `/FullInvoicesIntervention/createOrganizationInvoice`,
            {
              ...payload,
            },
          )
        },
        async onSuccess() {
          await queryClient.invalidateQueries()
          notify(`factures envoyées`, 'success', undefined, false, 1500)
        },
        onError(error) {
          console.error(error)
          notify(`erreur`, 'error', undefined, false, 1500)
        },
      }
    },
    getInvoicePdf(): UseMutationOptions<string, unknown, string> {
      return {
        async mutationFn(interventionReportId) {
          const { data } = await nxHttpClient.patch(
            '/FullInvoicesIntervention/getInvoiceAsPdf',
            {
              id: interventionReportId,
            },
            {
              responseType: 'blob',
            },
          )
          const fileBlob = new Blob([data], { type: 'application/pdf' })

          const fileUrl = window.URL.createObjectURL(fileBlob)
          return fileUrl
        },
      }
    },
  }
}

function isValidRange(start?: string, end?: string) {
  if (!start || !end) return false
  if (!isValidDate(new Date(start)) || !isValidDate(new Date(end))) return false
  return true
}

type ProPayload = {
  contractId: string
  contactId: string
  addressId: string
  fullInvoicesInterventionsIds: string[]
}

function encodeProPayload(
  invoicesMap: string[],
  contactIds: string[],
  addressIds: string[],
  proIds: string[],
): ProPayload {
  return {
    contractId: proIds[0],
    contactId: contactIds[0],
    addressId: addressIds[0],
    fullInvoicesInterventionsIds: invoicesMap,
  }
}
